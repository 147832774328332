<template>
  <div>
    <div style="margin: 10px 0px">
      <!--        <el-button size="small" type="primary" @click="addSubTableRow">新增</el-button>-->
      <el-button size="small" type="primary" @click="clickImport">导入产品设计单</el-button>
      <el-button size="small" type="primary" @click="sampleDialogShow">导入样品总控表</el-button>
      <el-button size="small" type="danger" @click="clickDel">删除</el-button>
      <el-button v-if="isCopy" :disabled="false" size="small" type="warning" @click="copySelectedColumnsToClipboard"> 辅料部专用复制 </el-button>
      <el-button v-if="dataForm.status === 2" :disabled="false" size="small" type="danger" @click="backRequ">退样 </el-button>
      <!--<el-button type="success" size="small" @click="showSyncDialog">同步工厂打样单</el-button>-->
      <el-button v-if="dataForm.requ_type === '1'" :disabled="false" size="small" type="danger" @click="cancelSampling"> 取消打样 </el-button>
      Tips: 需要取消打样/退样前请先线下沟通
      <el-input v-model="copyNum" placeholder="请输入" style="width: 250px" @input="val => (copyNum = keepNumber(val))">
        <template slot="prepend">复制</template>
        <template slot="append"> 份</template>
      </el-input>
      <el-button class="vg_ml_8" size="small" type="primary" @click="copyRowByNum">确定</el-button>
    </div>
    <DynamicUTable
      v-if="showFlag"
      ref="requSmplDetail"
      :row-move="false"
      :form="dataForm"
      :tableData="dataForm.dataList"
      :columns="subTableProperties"
      :isShow="dataForm.status === 2 ? false : isShow"
      :needPagination="false"
      :needSearch="false"
      :show-summary="false"
      :needClosedUpdate="true"
      :rowStyle="
        ({ row, rowIndex }) => {
          row.index = rowIndex;
          return { background: row.sample_status === 1 ? 'rgb(245,247,250)' : '', height: '80px' };
        }
      "
      @selection-change="handleSelectionChange"
    >
      <template v-slot:subItem-mould_no="scope">
        <el-link type="primary" class="vg_cursor" :disabled="isShow" @click="mouldDialogShow(scope.$index)">选择 </el-link>
      </template>
      <template v-slot:requ_smpl_taker="scope">
        <div class="vg_dis_flex">
          <el-select v-model="scope.row.requ_smpl_taker" clearable size="small">
            <el-option v-for="item in Object.values(userObj)" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-tag v-if="scope.row.taker_push_state === 2" type="success">已接收</el-tag>
          <el-tag v-if="scope.row.taker_push_state === 1" type="info">未接收</el-tag>
        </div>
      </template>
      <!--      //为非毛绒时 子表如果打样接收人和报价接收人不是同一个人，登录人为打样接收人时不能动报价和核价列（要求报价日期，核价接收人，报价接收人）-->
      <template v-slot:requ_smpl_quoter="scope">
        <div class="vg_dis_flex">
          <el-select
            v-model="scope.row.requ_smpl_quoter"
            clearable
            size="small"
            placeholder="请选择报价接收人"
            :disabled="dataForm.requ_type === '3' && scope.row.requ_smpl_taker !== scope.row.requ_smpl_quoter && scope.row.requ_smpl_taker === loginStffId"
          >
            <el-option v-for="item in Object.values(userObj)" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-tag v-if="scope.row.quoter_push_state === 2" type="success">已接收</el-tag>
          <el-tag v-if="scope.row.quoter_push_state === 1" type="info">未接收</el-tag>
        </div>
      </template>
      <!--      //为非毛绒时 子表如果打样接收人和报价接收人不是同一个人，登录人为打样接收人时不能动报价和核价列（要求报价日期，核价接收人，报价接收人）-->
      <template v-slot:requ_smpl_pricer="scope">
        <div class="vg_dis_flex">
          <el-select
            v-model="scope.row.requ_smpl_pricer"
            clearable
            size="small"
            :disabled="dataForm.requ_type === '3' && scope.row.requ_smpl_taker !== scope.row.requ_smpl_quoter && scope.row.requ_smpl_taker === loginStffId"
          >
            <el-option v-for="item in Object.values(userObj)" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-tag v-if="scope.row.pricer_push_state === 2" type="success">已接收</el-tag>
          <el-tag v-if="scope.row.pricer_push_state === 1" type="info">未接收</el-tag>
        </div>
      </template>
      <template v-slot:operation="scope">
        <div style="display: flex; flex-direction: column">
          <el-link v-if="dataForm.status === 2 && scope.row.sample_status === 1" class="vg_cursor" type="primary" @click="synchronizeInformation(scope.row)"
            >同步信息
          </el-link>
          <el-link class="vg_cursor" type="primary" @click="showOperationDialog(scope.row)">操作委托设计单稿件</el-link>
        </div>
      </template>
      <template v-slot:sample_status="scope">
        <el-tag v-if="scope.row.sample_status === 0" type="success">正常打样</el-tag>
        <el-tag v-else-if="scope.row.sample_status === 1" type="warning">已退回</el-tag>
        <el-tag v-else-if="scope.row.sample_status === 2" type="info">待取消</el-tag>
        <el-tag v-else-if="scope.row.sample_status === 3" type="danger">取消打样</el-tag>
        <el-tag v-else type="info">暂无</el-tag>
      </template>
    </DynamicUTable>
    <el-dialog title="设计工单" :visible.sync="dialogTableVisible" width="70%">
      <requImport @childDequ="childDequ" @pldequChildChanel="pldequChildChanel"></requImport>
    </el-dialog>
    <el-dialog title="样品总控表" :visible.sync="sampleDialogVisible" width="70%" top="10">
      <el-button type="primary" size="small" @click="addDataList" class="vg_mb_16">确认选择</el-button>
      <el-button type="info" size="small" @click="sampleTableRefresh" class="vg_mb_16">刷新</el-button>
      <search-table
        ref="subMultiTable"
        :data="sampleTableData"
        :tableRowKey="`smpl_id`"
        :needPagination="false"
        :columns="tableProperties"
        @selection-change="val => (sampleSelection = val)"
        @getTableData="getSampleData"
        :need-fixed-height="true"
        :rowStyle="
          ({ row, rowIndex }) => {
            row.index = rowIndex;
            return { height: '80px' };
          }
        "
      ></search-table>
    </el-dialog>
    <el-dialog title="模具信息" :visible.sync="mouldDialogVisible" width="70%">
      <el-button type="primary" size="small" @click="addMouldDataList" class="vg_mb_16">确认选择</el-button>
      <el-button type="info" size="small" @click="mouldTableRefresh" class="vg_mb_16">刷新</el-button>
      <search-table
        ref="subMouldTable"
        :data="mouldTableData"
        :tableRowKey="`mould_id`"
        :needPagination="false"
        :columns="tableMouldProperties"
        @selection-change="val => (mouldSelection = val)"
        @getTableData="getMouldData"
      ></search-table>
    </el-dialog>
    <el-dialog title="图片查看" :visible.sync="dialogVisible" width="50%">
      <el-row class="vd_dis">
        <el-image class="showImg" :src="showImgUrl" fit="contain"></el-image>
      </el-row>
    </el-dialog>
    <el-dialog title="退样说明" :visible.sync="requBackFlag" width="25%">
      <el-form ref="form" :model="requ_back">
        <div style="font-size: medium"><span style="color: red">*</span>退样数量</div>
        <el-form-item prop="requ_back_num" :rules="{ required: true, message: ' ', trigger: 'blur' }">
          <el-input
            type="input"
            v-model="requ_back.business_back_total"
            @input="val => (requ_back.business_back_total = keepNumber(val))"
            maxlength="7"
            show-word-limit
            placeholder="请填写退样数量"
            style="width: 440px"
          ></el-input>
        </el-form-item>
        <div style="font-size: medium"><span style="color: red">*</span>请输入退样原因</div>
        <el-form-item prop="business_back_reason" :rules="{ required: true, message: ' ', trigger: 'blur' }">
          <el-input
            type="textarea"
            rows="5"
            v-model="requ_back.business_back_reason"
            maxlength="300"
            show-word-limit
            placeholder="请输入退样原因"
            style="width: 440px"
          ></el-input>
        </el-form-item>
        <el-form-item style="text-align: center; margin-top: 10px; display: block">
          <el-button type="primary" @click="requBackSubmit">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="syncFactorySampleFormDialogVisible" title="操作委托设计单稿件" width="70%">
      <RequEditAbout :specialForm="specialForm" @successSync="syncFactorySampleFormDialogVisible = false"></RequEditAbout>
    </el-dialog>
  </div>
</template>

<script>
import requImport from './requImport2.vue';
import { myMixin } from '@/assets/js/mixin/tabMixin.js';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { dyjAPI } from '@api/modules/dyj';
import { requAPI } from '@api/modules/requ';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { keepNumber } from '@assets/js/regExUtil';
import { getDataCenterByPermId } from '@api/public';
import { changeisRequType3, changeStffId, requSmplTableProperties } from '@/views/DevelopManagement/RequManage/requ';
import { cloneDeep } from 'lodash';
import { deleteTableRow } from '@assets/js/arrayUtils';
import RequEditAbout from '@/views/component/docuCommon/docuAbout';

export default {
  name: 'contentTab',
  mixins: [myMixin],
  components: {
    DynamicUTable,
    SearchTable,
    requImport,
    RequEditAbout
  },
  inject: ['initData'],
  props: {
    dataForm: {
      type: Object,
      default: () => {}
    },
    isShow: {
      type: Boolean,
      required: false,
      default: false
    },
    userObj: {
      type: Object,
      default: () => {}
    },
    isShowMouldNo: { type: Boolean, required: false, default: false },
    requ_smpl_made_list: { type: Array, required: false },
    prod_unit_list: { type: Array, required: false }
  },
  computed: {
    isCopy() {
      if (this.$route.path === '/requ_add') return false;
      let { dept_name, stff_id } = this.$cookies.get('userInfo');
      return dept_name === '辅料部' || stff_id === 1;
    },
    subTableProperties() {
      const requType = Number(this.dataForm.requ_type);
      const isRequType1 = requType === 1;
      const isRequType2 = requType === 2;
      const isRequType3 = requType === 3;
      changeisRequType3(isRequType3);
      changeStffId(this.$cookies.get('userInfo').stff_id);
      this.requSmplTableProperties.forEach(column => {
        const prop = column.prop;
        switch (prop) {
          case 'imge_id':
            column.subItem.required = isRequType2;
            break;
          case 'operation':
          case 'fequ_supp_abbr':
            column.visible = isRequType1;
            break;
          case 'sample_status':
            column.visible = isRequType1;
            break;
          case 'prod_ename':
          case 'eries_name':
          case 'cust_num':
            const { dept_name, stff_id } = this.$cookies.get('userInfo');
            column.visible = dept_name === '业务五部' || stff_id === 1;
            break;
          case 'requ_smpl_type':
            column.subItem.options = isRequType1 || isRequType2 ? this.typeList1 : this.typeList2;
            column.subItem.change = (val, row) => {
              column.subItem.required = ![2, 4].includes(val);
              if (isRequType3 && val === 4) {
                // 当样品种类为二部样品类型为报价时设置委托类型默认为仅报价
                row.requ_smpl_quot = 6;
              }
            };
            break;
          case 'prod_type':
            column.subItem.options = this.prodTypeList;
            column.subItem.change = (val, row) => this.changeProdType(val, row);
            break;
          case 'requ_smpl_quot':
            let options = [];
            if (isRequType1) options = this.quotList;
            if (isRequType2) options = this.quotList1;
            if (isRequType3) options = this.quotList2;
            // 当样品种类为二部样品类型为报价时委托类型禁用
            column.subItem.disabled = row => isRequType3 && row.requ_smpl_type === 4;
            column.subItem.options = options;
            column.subItem.change = (val, row) => {
              column.subItem.required = ![2, 4].includes(val);
            };
            break;
          case 'difficulty_level':
            column.visible = isRequType3;
            column.subItem.options = this.difficultyLevel;
            break;
          case 'mould_no':
            column.visible = isRequType3;
            break;
          case 'prod_unit':
            column.subItem.options = this.prod_unit_list;
            break;
          case 'requ_smpl_made':
            if (isRequType1) {
              column.subItem.options = this.requ_smpl_made_list?.filter(x => x.param2 === '毛绒') || [];
            } else if (isRequType2) {
              column.subItem.options = this.requ_smpl_made_list?.filter(x => x.param2 === '辅材') || [];
            } else if (isRequType3) {
              column.subItem.options = this.requ_smpl_made_list?.filter(x => x.param2 === '非毛绒') || [];
            }
            break;
          case 'requ_smpl_taker':
          case 'requ_smpl_quoter':
          case 'requ_smpl_pricer':
            column.subItem.options = this.userObj;
            break;
          case 'requ_smpl_num':
            column.subItem.change = () => this.numChange();
            break;
          case 'requ_smpl_pedate':
            column.subItem.change = (val, row) => this.updateData(row.index);
            break;
        }
      });
      this.$refs.requSmplDetail?.reloadTable();
      if (isRequType1) {
        const startTargetProperties = ['prod_name', 'requ_smpl_quot', 'requ_smpl_type'];
        const endTargetProperties = ['desi_no', 'dequ_no'];
        const startProps = [];
        const middleProps = [];
        const endProps = [];
        this.requSmplTableProperties.forEach(property => {
          if (startTargetProperties.includes(property.prop)) {
            startProps.push(property);
          } else if (!startTargetProperties.concat(endTargetProperties).includes(property.prop)) {
            middleProps.push(property);
          } else if (endTargetProperties.includes(property.prop)) {
            endProps.push(property);
          }
        });
        return [...startProps, ...middleProps, ...endProps];
      }
      if (isRequType3) {
        const targetProperties = ['prod_no', 'prod_name', 'requ_smpl_made', 'requ_smpl_quot', 'requ_smpl_type', 'difficulty_level', 'requ_smpl_pedate'];
        const targetProperties2 = ['sample_status', 'operation', 'imge_id', 'requ_smpl_taker', 'requ_smpl_quoter', 'requ_smpl_pricer'];
        const firstProps = [];
        const secondProps = [];
        const otherProps = [];

        this.requSmplTableProperties.forEach(property => {
          if (targetProperties.includes(property.prop)) {
            firstProps.push(property);
          } else if (targetProperties2.includes(property.prop)) {
            secondProps.push(property);
          } else {
            otherProps.push(property);
          }
        });

        return [...firstProps, ...secondProps, ...otherProps];
      }
      return this.requSmplTableProperties;
    }
  },
  data() {
    return {
      loginStffId: this.$cookies.get('userInfo').stff_id,
      requSmplTableProperties: requSmplTableProperties,
      tableProperties: [
        this.$store.state.selection,
        this.$store.state.index,
        {
          prop: 'smpl_type',
          itemType: 'select',
          label: '样品类型',
          options: [
            { value: 1, label: '新款' },
            { value: 2, label: '老款' },
            { value: 3, label: '修改款' },
            { value: 4, label: '报价' },
            { value: 5, label: '其他' }
          ],
          widthAuto: true,
          sortable: false,
          formatter: val => this.typeList.find(x => x.value === val).label
        },
        { prop: 'smpl_no', itemType: 'input', label: '样品编号', widthAuto: true, sortable: false },
        { prop: 'smpl_name', itemType: 'input', label: '样品名称', widthAuto: true, sortable: false },
        { prop: 'smpl_num', itemType: 'input', label: '样品数量', widthAuto: true },
        { prop: 'smpl_stff_name', itemType: 'input', label: '开版师', widthAuto: true, sortable: false }
      ],
      tableMouldProperties: [
        this.$store.state.selection,
        this.$store.state.index,
        {
          label: '产品图片',
          prop: 'imge_url',
          itemType: 'input',
          input: false,
          sortable: false,
          labelWidth: '220',
          subItem: { type: 'image', image: row => row.imge_url, thumbnail: row => row.imge_url }
        },
        { prop: 'mould_no', itemType: 'input', label: '模具编号', widthAuto: true, sortable: false },
        { prop: 'modr_name', itemType: 'input', label: '模具名称', widthAuto: true, sortable: false },
        { prop: 'prod_no', itemType: 'input', label: '我司货号', input: false, widthAuto: true, sortable: false }
      ],
      dialogVisible: false,
      showImgUrl: '',
      sampleTableData: [],
      mouldTableData: [],
      sampleSelection: [],
      mouldSelection: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e6;
        }
      },
      showFlag: false,
      selectIndex: null,
      sampleDialogVisible: false,
      mouldDialogVisible: false,
      dialogTableVisible: false,
      isShowfor5dept: false,
      pedataRules: true,
      selectTab: [],
      typeList: [
        { value: 1, label: '新款' },
        { value: 2, label: '老款' },
        { value: 3, label: '修改款' },
        { value: 4, label: '报价' },
        { value: 5, label: '其他' }
      ],
      typeList1: {
        1: { value: 1, label: '新款' },
        2: { value: 2, label: '老款' },
        3: { value: 3, label: '修改款' }
      },
      typeList2: {
        1: { value: 1, label: '新款' },
        2: { value: 2, label: '老款' },
        3: { value: 3, label: '修改款' },
        4: { value: 4, label: '报价' },
        5: { value: 5, label: '其他' }
      },
      quotList: {
        1: { value: 1, label: '打样并报价' },
        2: { value: 2, label: '试样报价' },
        3: { value: 3, label: '仅打样' },
        4: { value: 4, label: '估价' },
        5: { value: 5, label: '返工' }
      },
      quotList1: {
        1: { value: 1, label: '打样并报价', disabled: false },
        6: { value: 6, label: '仅报价', disabled: false },
        3: { value: 3, label: '仅打样', disabled: false },
        7: { value: 7, label: '其他', disabled: false }
      },
      quotList2: {
        1: { value: 1, label: '打样并报价', disabled: false },
        6: { value: 6, label: '仅报价', disabled: false },
        3: { value: 3, label: '仅打样', disabled: false }
      },
      prodTypeList: [],
      delList: [],
      difficultyLevel: {
        1: { label: 'A', value: 'A' },
        2: { label: 'B', value: 'B' },
        3: { label: 'C', value: 'C' },
        4: { label: 'D', value: 'D' }
      },
      requBackFlag: false,
      requ_back: {
        business_back_total: 0,
        business_back_reason: '',
        requ_smpl_id: null,
        requ_taker: null
      },
      subTableMap: new Map(),
      syncFactorySampleFormDialogVisible: false,
      specialForm: { requ_smpl_id: null },
      copyNum: ''
    };
  },
  async mounted() {
    this.showFlag = false;
    this.for5dept();
    this.prodTypeList = await getDataCenterByPermId({ id: 10004, type: 'select', otherColumn: 'param2' });
    this.changeProperties();
    setTimeout(() => (this.showFlag = true), 500);
  },
  methods: {
    keepNumber,
    changeProperties() {},
    //导入样品总控表
    sampleDialogShow() {
      this.sampleDialogVisible = true;
      setTimeout(() => this.getSampleData(), 500);
    },
    //获取模具信息
    mouldDialogShow(index) {
      this.selectIndex = index;
      this.mouldDialogVisible = true;
      setTimeout(() => this.getMouldData(), 500);
    },
    //获取样品总控表list
    getSampleData() {
      dyjAPI.getDyjSmpls(this.$refs.subMultiTable.searchForm).then(({ data }) => {
        this.sampleTableData = data.list;
      });
    },
    ////获取模具信息list
    getMouldData() {
      this.$refs.subMouldTable.searchForm.page_no = 1;
      requAPI.getmouldSmpls(this.$refs.subMouldTable.searchForm).then(({ data }) => {
        this.mouldTableData = data.list;
      });
    },
    /**
     * 样品总控表数据添加至表格中
     */
    addDataList() {
      if (this.sampleSelection.length === 0) {
        return this.$message.warning('请至少选择一条数据!');
      }
      this.sampleSelection.forEach(item => {
        let obj = Object.assign(cloneDeep(this.$refs.requSmplDetail.originalObject), item);
        obj.dequ_no = item.requ_no;
        obj.group_type = 0;
        obj.requ_smpl_type = item.smpl_type;
        obj.requ_smpl_num = item.smpl_num;
        obj.prod_name = item.smpl_name;
        obj.requ_smpl_pricer = this.dataForm.requ_pricer;
        obj.requ_smpl_quoter = this.dataForm.requ_quoter;
        obj.requ_smpl_taker = this.dataForm.requ_taker;
        obj.prod_spec = item.smpl_spec;
        obj.requ_smpl_pedate = this.dataForm.requ_pedate; //要求交样日期
        obj.prod_no = item.smpl_no;
        obj.prod_ename = item.smpl_name_en;
        obj.requ_smpl_quot = item.smpl_quot;
        obj.prod_cust_no = item.smpl_cust_no;
        obj.prod_suffix = item.smpl_suffix;
        obj.requ_smpl_qudate = this.dataForm.requ_smpl_qudate; //要求核价日期
        obj.destroy_flag = 0;
        obj.prod_unit = '个';
        obj.requ_smpl_made = '毛绒';
        if (!obj.imge_id) {
          obj.imge_id = null;
          obj.picture_url = null;
        }
        this.dataForm.dataList.push(obj);
      });
      this.numChange();
      this.sampleDialogVisible = false;
    },
    //更改要求交样日期时联动核价日期并+1天
    updateData(index) {
      let date = this.dataForm.dataList[index].requ_smpl_pedate || 0;
      if (date) {
        this.$set(this.dataForm.dataList[index], 'requ_smpl_qudate', date + 86400000);
      }
    },
    /**
     * 样品总控表数据添加至表格中
     */
    addMouldDataList() {
      if (this.mouldSelection.length === 0 || this.mouldSelection.length > 1) {
        return this.$message.warning('请选择一条数据!');
      }
      this.$set(this.dataForm.dataList[this.selectIndex], 'mould_no', this.mouldSelection[0].mould_no);
      this.$set(this.dataForm.dataList[this.selectIndex], 'mould_id', this.mouldSelection[0].mould_id);
      this.$set(this.dataForm.dataList[this.selectIndex], 'imge_url', this.mouldSelection[0].imge_url);
      this.numChange();
      this.mouldDialogVisible = false;
    },
    /**
     * 导入样品总控表刷新
     */
    sampleTableRefresh() {
      this.$refs.subMultiTable.resetFields();
      this.getSampleData();
    },
    /**
     * 模具信息刷新
     */
    mouldTableRefresh() {
      this.$refs.subMouldTable.resetFields();
      this.getMouldData();
    },
    //只有业务五部能看到
    for5dept() {
      let { dept_id } = this.$cookies.get('userInfo');
      if (dept_id === 28) {
        this.isShowfor5dept = true;
      }
    },
    changeProdType(val, row) {
      this.$set(row, 'prod_type_code', this.prodTypeList.find(({ label }) => label === val)?.param2);
    },
    handleSelectionChange(val) {
      this.selectTab = val;
    },
    clickImport() {
      this.dialogTableVisible = true;
    },
    pldequChildChanel() {
      this.dialogTableVisible = false;
    },
    childDequ(val) {
      this.dialogTableVisible = false;
      val.forEach(item => {
        let obj = Object.assign(cloneDeep(this.$refs.requSmplDetail.originalObject), item);
        obj.requ_smpl_taker = this.dataForm.requ_taker;
        obj.requ_smpl_pricer = this.dataForm.requ_pricer;
        obj.requ_smpl_quot = null;
        obj.requ_smpl_num = 1;
        obj.group_type = 0;
        obj.requ_smpl_made = '毛绒';
        //要求交样日期 和 要求报价日期
        if (this.dataForm.requ_pedate) {
          obj.requ_smpl_pedate = this.dataForm.requ_pedate;
        }
        if (this.dataForm.requ_smpl_qudate) {
          obj.requ_smpl_qudate = this.dataForm.requ_smpl_qudate;
        }
        //查看是否有 报价接收人
        if (this.dataForm.requ_quoter) {
          obj.requ_smpl_quoter = this.dataForm.requ_quoter;
        }
        //打样接收人
        if (this.dataForm.requ_taker) {
          obj.requ_smpl_taker = this.dataForm.requ_taker;
        }
        //查看是否有核价接收人
        if (this.dataForm.requ_pricer) {
          obj.requ_smpl_pricer = this.dataForm.requ_pricer;
        }
        obj.prod_unit = '个';
        obj.requ_smpl_type = item.desi_type;
        obj.picture_url = item.imge_url;
        obj.imge_id = item.imge_id;
        if (!obj.imge_id) {
          obj.imge_id = null;
          obj.picture_url = null;
        }
        this.dataForm.dataList.push(obj);
      });
      // value = value.filter(item => this.dataForm.dataList.every(item1 => item.desi_no !== item1.desi_no));
      // this.dataForm.dataList = this.dataForm.dataList.concat(val);
      this.dataForm.dataList.forEach(item => {
        if (item.destroy_flag === undefined) {
          item.destroy_flag = 0;
        }
      });
      this.numChange();
    },
    //重新计算样品数量
    numChange() {
      let toatal = 0;
      this.dataForm.dataList.forEach(item => {
        toatal += Number(item.requ_smpl_num);
      });
      this.dataForm.requ_num = toatal.toFixed(2);
    },
    //退样
    backRequ() {
      if (this.selectTab.length === 0) {
        return this.$message.warning('至少选择一条数据');
      }
      if (this.selectTab.length > 1) {
        return this.$message.warning('只能选择一条数据');
      }
      //默认为选中数据的样品数量
      this.requ_back.business_back_total = cloneDeep(this.selectTab[0].requ_smpl_num);
      this.requBackFlag = true;
    },
    //确认退样
    requBackSubmit() {
      if (!this.requ_back.business_back_total) {
        return this.$message.error('请填写退样数量');
      }
      if (!this.requ_back.business_back_reason) {
        return this.$message.error('请填写退样原因');
      }
      if (this.requ_back.business_back_total > this.selectTab[0].requ_smpl_num) {
        return this.$message.error('退样数量不可大于样品数量');
      }
      this.requ_back.requ_smpl_id = this.selectTab[0].requ_smpl_id; //子表id
      this.requ_back.requ_taker = this.selectTab[0].requ_smpl_taker; //打样接收人id
      requAPI.editBusinessBack(this.requ_back).then(data => {
        if (data.code === 0) {
          this.requ_back = {
            business_back_total: 0,
            business_back_reason: '',
            requ_smpl_id: null,
            requ_taker: null
          };
          this.requBackFlag = false;
          //刷新
          this.$emit('buttonRefresh');
          this.$refs.requSmplDetail.clearSelection();
          return this.$message.success('退样成功');
        }
      });
    },
    clickDel(list = []) {
      if (this.selectTab.length === 0) {
        return this.$message.warning('至少选择一条数据');
      }
      this.$confirm('确定移除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let { tableData, map } = deleteTableRow('requ_smpl_id', this.dataForm.dataList, this.subTableMap, list.length > 0 ? list : this.selectTab);
          this.dataForm.dataList = tableData;
          this.subTableMap = map;
          //重新计算样品数量
          this.numChange();
        })
        .catch(() => {});
    },
    addSubTableRow() {
      this.dataForm.dataList.push({
        prod_unit: '个',
        requ_smpl_pedate: new Date(),
        requ_smpl_taker: this.dataForm.requ_taker,
        requ_smpl_pricer: this.dataForm.requ_pricer
      });
    },
    cancelSampling() {
      if (this.selectTab.length === 0) return this.$message.warning('请选择数据!');
      this.$prompt('请填写取消原因!', '确定取消打样?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        inputType: 'textarea',
        inputValidator: value => (value ? true : '请填写取消原因')
      }).then(({ value }) => {
        let data = cloneDeep(this.selectTab);
        data.forEach(item => (item.cancelReason = value));
        requAPI.editSampleStatus(data).then(({ data }) => {
          this.$refs.requSmplDetail.clearSelection();
          this.$message.success('取消打样成功!');
          this.initData();
        });
      });
    },
    showSyncDialog() {
      if (this.selectTab.length !== 1) return this.$message.warning('请选择一条数据!');
      this.specialForm = this.selectTab[0];
      this.syncFactorySampleFormDialogVisible = true;
    },
    showOperationDialog(row) {
      this.specialForm = row;
      this.syncFactorySampleFormDialogVisible = true;
    },
    synchronizeInformation(row) {
      row.requ_smpl_pedate ? (row.requ_smpl_pedate /= 1000) : '';
      row.requ_smpl_qudate ? (row.requ_smpl_qudate /= 1000) : '';
      requAPI.editFequSample(row).then(({ data }) => {
        this.$message.success('同步成功!');
        this.initData();
      });
    },
    async copySelectedColumnsToClipboard() {
      // 构建表头
      // const columnsToCopy = [
      //   'prod_no', // 我司货号
      //   'prod_cust_no', // 客户货号
      //   'requ_smpl_num', // 样品数量
      //   'prod_unit', // 样品单位
      //   'requ_smpl_type', // 样品类型
      //   'requ_smpl_made', // 样品材质
      //   'requ_smpl_quot', // 委托类型
      //   'requ_smpl_pedate' // 要求交样日期
      // ];
      // const headers = columnsToCopy
      //   .map(prop => {
      //     return this.requSmplTableProperties.find(item => item.prop === prop)?.label || prop;
      //   })
      //   .join('\t');
      // let dataAsText = headers + '\n'; // 首先添加表头，末尾添加换行符
      let dataAsText = ''; // 首先添加表头，末尾添加换行符

      let index = 1; // 初始化索引
      for (const row of this.dataForm.dataList) {
        let rowData = `${index}\t`;
        index++;

        // 按照表头顺序处理数据
        rowData += `${row.prod_cust_no || ''}\t`;
        rowData += `${row.requ_smpl_num || ''}\t`;
        rowData += `${row.prod_unit || ''}\t`;

        // 特殊处理
        const requ_smpl_type_label = this.requSmplTableProperties.find(item => item.prop === 'requ_smpl_type').subItem.options[row.requ_smpl_type].label;
        rowData += `${requ_smpl_type_label}\t`;
        rowData += `${row.requ_smpl_made || ''}\t`;
        const requ_smpl_quot_label = this.requSmplTableProperties.find(item => item.prop === 'requ_smpl_quot').subItem.options[row.requ_smpl_quot].label;
        rowData += `${requ_smpl_quot_label}\t`;
        const requ_smpl_pedate_str = row.requ_smpl_pedate ? new Date(row.requ_smpl_pedate).toLocaleDateString() : '';
        rowData += `${requ_smpl_pedate_str}\t`;
        // 每行数据末尾移除最后一个多余的制表符，并添加换行符
        dataAsText += rowData.slice(0, -1) + '\n';
      }
      try {
        await navigator.clipboard.writeText(dataAsText.trim());
        this.$message.success('已复制到剪贴板');
      } catch (err) {
        this.$message.error('复制到剪贴板失败，请重试');
        console.error('复制到剪贴板失败:', err);
      }
    },
    copyRowByNum() {
      if (this.selectTab.length !== 1) return this.$message.warning('请选择一条数据!');
      if (this.copyNum <= 0) return this.$message.warning('请输入正确的复制份数!');
      let clone = cloneDeep(this.selectTab[0]);
      clone.requ_smpl_id = null;
      clone.generate_status = 0;
      clone.fequ_supp_abbr = null;
      clone.sample_status = 0;
      clone.business_back_num = 0;
      for (let i = 0; i < this.copyNum; i++) {
        this.dataForm.dataList.push(clone);
      }
      this.$refs.requSmplDetail.clearSelection();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .textarea {
  margin-bottom: 4px;
}

::v-deep .fixed-cell {
  //  color:red !important
  background-color: #ccc !important;
}

::v-deep .el-table__body-wrapper {
  z-index: 2;
}

::v-deep .el-form-item.is-required .el-form-item__label:before {
  content: '';
  display: none;
}

::v-deep .el-form-item__label {
  display: none;
}

.show-overflow-tooltip {
  display: block;
  overflow: hidden;
  height: 0;
}

.showImg {
  display: flex;
  align-items: center;
}

::v-deep .el-form-item__content {
  line-height: 0;
}
</style>
