<template>
  <div class="requAddMain">
    <div>
      <el-form :model="dataForm" :inline="true" ref="formDataRef" :rules="dataFormRule" label-width="120px">
        <div v-sticky class="header_requ" style="padding-top: 16px; background: white">
          <addHeader
            @submit="submit()"
            @cancel="cancel"
            :formData="dataForm"
            :propInfo="{ name: '委托打样单号:', no: 'requ_no', placeholder: '根据员工打样编号生成', disabled: true }"
          />
        </div>
        <contentForm :dataForm="dataForm" :statusType="false" :userList="userList" @changeTableDate="changeTableDate" @updateFormData="updateFormData" />
        <el-tabs v-model="activeName" type="border-card">
          <el-tab-pane :key="'first'" label="有我司货号" name="first">
            <contentTable
              :isShowMouldNo="true"
              :dataForm="dataForm"
              ref="contentTableRef"
              :userObj="userObj"
              :requ_smpl_made_list="requ_smpl_made_list"
              :prod_unit_list="prod_unit_list"
            />
          </el-tab-pane>
          <el-tab-pane v-if="dataForm.requ_type !== '3'" :key="'second'" label="无我司货号" name="second">
            <contentTableArtNo :dataForm="dataForm" ref="contentTableArtRef" :userList="userList" :prod_unit_list="prod_unit_list" />
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </div>
    <el-row class="vg_mt_16">
      <el-col>
        <inputUser :isCookie="true" ref="userData"></inputUser>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import contentForm from './cpn/contentForm.vue';
import addHeader from '@/views/component/addHeaderInput.vue';
import inputUser from '@/views/component/inputUser';
import contentTable from './cpn/contentTable.vue';
import { getNoCatch, post } from '@/api/request';
import { requAPI } from '@/api/modules/requ';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import contentTableArtNo from '@/views/DevelopManagement/RequManage/TabChild/cpn/contentTableArtNo.vue';
import { cloneDeep } from 'lodash';
import { stffAPI } from '@api/modules/staff';
import { getDataCenterByPermId } from '@api/public';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'RequAddMain',
  components: {
    contentTableArtNo,
    contentForm,
    addHeader,
    inputUser,
    contentTable
  },
  provide() {
    return {
      initData: this.initData
    };
  },
  data() {
    return {
      dataForm: {
        cust_abbr: '',
        requ_id: null, // 委托打样单id
        requ_no: null, //委托打样单 单号
        cust_id: null, //客户id
        requ_num: 1, //打样数量 两位小数
        requ_pedate: null, //要求交期 date
        requ_sample: '0', //是 否提供原样 1是 0否
        requ_content: null, //原样要求
        requ_stff_id: null, //委托人
        requ_dept_id: null, //打样部门
        requ_quoter: null, //报价接收人
        requ_taker: null, //打样接收人
        requ_pricer: null, //核价接收人
        user_id: null,
        stff_id: null,
        cptt_id: null,
        dept_id: null,
        dept_team_id: null,
        requ_type: null,
        requ_smpl_qudate: null,
        dataList: [],
        dataList2: [],
        requ_num_mode: null,
        sub_time: new Date().getTime()
      },
      dataFormRule: {
        cust_id: [{ required: true, message: ' ', trigger: 'blur' }],
        requ_type: [{ required: true, message: ' ', trigger: 'blur' }],
        requ_num: [{ required: true, message: ' ', trigger: 'blur' }],
        requ_pedate: [{ required: true, message: ' ', trigger: 'blur' }],
        cptt_id: [{ required: true, message: ' ', trigger: 'blur' }],
        requ_content: [{ required: true, message: ' ', trigger: 'blur' }],
        requ_stff_id: [{ required: true, message: ' ', trigger: 'blur' }]
        // requ_quoter: [{ required: true, message: ' ', trigger: 'blur' }],
        // requ_taker: [{ required: true, message: ' ', trigger: 'blur' }],
        // requ_pricer: [{ required: true, message: ' ', trigger: 'blur' }],
      },
      userObj: {},
      userList: [],
      activeName: 'first',
      requ_smpl_made_list: [],
      prod_unit_list: []
    };
  },
  watch: {
    'dataForm.requ_dept_id'(val) {
      if (val) {
        getNoCatch(stffAPI.getAllStffsV1, { dept_id: val }).then(({ data }) => {
          let tempList = [];
          let tempObj = {};
          data.forEach(item => {
            let subTemp = { value: item.stff_id, label: item.stff_name };
            tempObj[item.stff_id] = subTemp;
            tempList.push(subTemp);
          });
          this.userList = tempList;
          this.userObj = tempObj;
        });
      } else {
        this.dataForm.requ_taker = null;
        this.dataForm.requ_pricer = null;
        this.dataForm.requ_quoter = null;
        this.userObj = {};
        this.userList = [];
      }
    }
  },
  created() {
    //默认等于登录人
    this.dataForm.requ_stff_id = this.$cookies.get('userInfo').stff_id;
    //默认等于登录人的公司
    this.dataForm.cptt_id = this.$cookies.get('userInfo').cptt_id;
    this.getDataInfo();
  },
  async mounted() {
    if (this.$route.params.syncData) this.dataForm.dataList = this.$route.params.syncData;
  },
  methods: {
    initData() {},
    async getDataInfo() {
      this.prod_unit_list = await getDataCenterByPermId({
        id: 10008,
        type: 'select'
      });
      this.requ_smpl_made_list = await getDataCenterByPermId({
        id: 10009,
        type: 'select',
        otherColumn: 'param2'
      });
    },
    updateFormData(val) {
      this.formData.cust_id = val.cust_id;
      this.formData.requ_num = val.requ_num;
      this.formData.requ_pedate = val.requ_pedate;
      this.formData.requ_sample = val.requ_sample;
      this.formData.requ_stff_id = val.requ_stff_id;
      this.formData.requ_no = val.requ_no;
      this.formData.requ_dept_id = val.requ_dept;
      this.formData.requ_pricer = val.requ_pricer;
      this.formData.requ_content = val.requ_content;
    },
    updataDataForm2(val) {
      this.dataForm.dataList = val.dataList;
      this.dataForm.requ_num = val.requ_num;
    },
    submit() {
      this.$confirm('确定保存？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let dynamicUTable = await this.$refs.contentTableRef?.$refs.requSmplDetail.fullValidateMsg();
          this.$refs.formDataRef.validate((valid, object) => {
            if (valid && !dynamicUTable) {
              this.saveFun();
            } else {
              let tempStr = '';
              if (dynamicUTable) tempStr += `子表-${dynamicUTable}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch(() => {});
    },
    saveFun() {
      let dataForm = cloneDeep(this.dataForm);
      const form = {};
      form.requ_no = dataForm.requ_no;
      form.cust_id = dataForm.cust_id;
      form.requ_num = dataForm.requ_num;
      if (dataForm.requ_pedate instanceof Date) {
        form.requ_pedate = dataForm.requ_pedate.getTime() / 1000;
      } else {
        form.requ_pedate = dataForm.requ_pedate / 1000;
      }
      if (dataForm.requ_smpl_qudate instanceof Date) {
        form.requ_smpl_qudate = dataForm.requ_smpl_qudate ? dataForm.requ_smpl_qudate.getTime() / 1000 : null;
      } else {
        form.requ_smpl_qudate = dataForm.requ_smpl_qudate ? dataForm.requ_smpl_qudate / 1000 : null;
      }
      form.requ_sample = dataForm.requ_sample;
      form.requ_content = dataForm.requ_content;
      form.requ_stff_id = dataForm.requ_stff_id;
      form.requ_dept_id = dataForm.requ_dept_id;
      form.requ_quoter = dataForm.requ_quoter ? dataForm.requ_quoter : 0;
      form.requ_taker = dataForm.requ_taker ? dataForm.requ_taker : 0;
      form.requ_pricer = dataForm.requ_pricer ? dataForm.requ_pricer : 0;
      form.requ_smpl_list = dataForm.dataList;
      form.requ_nsmpl_list = dataForm.dataList2;
      form.requ_type = dataForm.requ_type;
      form.cptt_id = dataForm.cptt_id;
      form.requ_smpl_list.forEach(item => {
        item.requ_smpl_pedate /= 1000;
        item.requ_smpl_qudate = item.requ_smpl_qudate ? item.requ_smpl_qudate / 1000 : null;
        item.requ_smpl_quoter = !item.requ_smpl_quoter ? 0 : item.requ_smpl_quoter;
        item.requ_smpl_taker = !item.requ_smpl_taker ? 0 : item.requ_smpl_taker;
        item.requ_smpl_pricer = !item.requ_smpl_pricer ? 0 : item.requ_smpl_pricer;
        item.prod_suffix = item.prod_suffix ? item.prod_suffix : 0;
        item.requ_smpl_num = item.requ_smpl_num ? item.requ_smpl_num : 0.0;
      });
      form.requ_nsmpl_list.forEach(item => {
        item.requ_smpl_pedate = this.helper.getNewDate(item.requ_smpl_pedate);
      });
      const userInfo = this.$cookies.get('userInfo');
      form.user_id = userInfo.user_id;
      form.stff_id = userInfo.stff_id;
      form.dept_id = userInfo.dept_id;
      form.dept_team_id = userInfo.dept_team_id;
      form.requ_num_mode = dataForm.requ_num_mode; //款数
      form.status = dataForm.status;
      //提交时间
      if (dataForm.sub_time instanceof Date) {
        form.sub_time = dataForm.sub_time ? dataForm.sub_time.getTime() : null;
      } else {
        form.sub_time = dataForm.sub_time ? dataForm.sub_time : null;
      }
      post(requAPI.addRequ, form)
        .then(res => {
          if (res.data.code === 0) {
            this.$message.success(res.data.msg);
            this.dataForm.dataList = [];
            this.dataForm.dataList2 = [];
            // this.$nextTick(() => {
            //   this.$refs.formDataRef.resetFields();
            // });
            const permId = this.$route.query.perm_id;
            this.$EventBus.$emit('close_current_tags');
            this.jump('/requ_edit', {
              key: UrlEncode.encode(
                JSON.stringify({
                  perm_id: permId,
                  form_id: res.data.data.form_id
                })
              )
            });
            return;
          }

          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.dataForm.dataList = [];
          this.$nextTick(() => {
            this.$refs.formDataRef.resetFields();
          });
          const permId = this.$route.query.perm_id;
          this.jump('/requ_list?perm_id=' + permId);
        })
        .catch(() => {});
    },
    changeTableDate(val) {
      //有我司货号
      this.dataForm.dataList.forEach(item => {
        item.requ_smpl_pedate = val;
      });
      //无我司货号
      this.dataForm.dataList2.forEach(item => {
        this.$set(item, 'requ_smpl_pedate', val);
      });
    }
  }
};
</script>

<style scoped></style>
