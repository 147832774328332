<template>
  <div class="vg_wrapper">
    <div class="vd_search_group">
      <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getCustList()">
        <el-row>
          <el-col :md="7">
            <el-form-item label="客户编号:" prop="cust_no">
              <el-input size="small" v-model.trim="searchForm.cust_no" clearable placeholder="请填写客户编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="7">
            <el-form-item label="客户简称:" prop="cust_abbr">
              <el-input size="small" v-model.trim="searchForm.cust_abbr" clearable placeholder="请填写客户简称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="7">
            <el-form-item label="客户属性:">
              <el-select size="small" filterable v-model="searchForm.cust_attr" placeholder="请选择客户属性" clearable>
                <el-option v-for="item in attr" :key="item.param2" :label="item.param1" :value="item.param1"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-form-item>
            <el-button size="small" type="primary" icon="el-icon-search" @click="getDequsNow()" class="vg_ml_16">查询 </el-button>
            <el-button size="small" type="info" icon="el-icon-refresh" @click="getDequsNowO()" class="vg_ml_16">刷新 </el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <el-row class="vd_mrt">
      <el-col :md="24">
        <el-table ref="multiTable" v-loading="loading" :data="custList" :row-key="getRowKey" border class="vg_pointer" @cell-dblclick="cellDbclick">
          <el-table-column label="客户编号" prop="cust_no" align="center" />
          <el-table-column label="客户属性" :show-overflow-tooltip="true" prop="cust_attr" align="center"> </el-table-column>
          <el-table-column label="客户简称" prop="cust_abbr" align="center" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column label="客户全称" prop="cust_name" align="center" :show-overflow-tooltip="true" />
          <el-table-column label="国家" prop="cust_country" align="center"></el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <pubPagination ref="pubPagination" :totalPage="totalPage" @changePageSearch="changePageSearch"></pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get } from '@api/request';
import { custAPI } from '@api/modules/cust';
import pubPagination from '@/components/common/pubPagination';
import helper from '@assets/js/helper.js';
import { optnAPI } from '@/api/modules/optn';

export default {
  name: 'DequImport',
  components: {
    pubPagination
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        page_no: 1,
        cust_no: null,
        cust_abbr: null,
        cust_attr: null
      },
      totalPage: 0,
      btn: {},
      loading: true,
      dequStffList: [],
      custList: [],
      currentPage: 1,
      attr: [],
      title: '提示：相同数据仅限导入一条'
    };
  },
  created() {
    this.initData();
    this.getAttr();
  },
  filters: {
    formatDequPrice(row) {
      return helper.haveFour(row);
    }
  },
  methods: {
    initData() {
      this.getCustList();
    },
    // 获取客户
    getCustList() {
      let { dept_name, dept_id } = this.$cookies.get('userInfo');
      let currentDeptId = ['业务一部', '业务二部', '业务五部'].includes(dept_name) ? dept_id : null;
      get(custAPI.getAllCustsV3, {
        page_no: this.searchForm.page_no,
        cust_no: this.searchForm.cust_no,
        cust_abbr: this.searchForm.cust_abbr,
        cust_attr: this.searchForm.cust_attr,
        status: 2,
        dept_id: currentDeptId
      })
        .then(res => {
          if (res.data.code === 0) {
            this.custList = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            this.loading = false;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 查询方法
    getDequsNow() {
      this.$refs.pubPagination.currentPage = 1;
      this.searchForm.page_no = 1;
      this.loading = true;
      console.log(this.searchForm);
      this.getCustList();
    },
    // 刷新
    getDequsNowO() {
      this.$refs.pubPagination.currentPage = 1;
      this.loading = true;
      this.searchForm.page_no = 1;
      this.searchForm.cust_abbr = null;
      this.searchForm.cust_no = null;
      this.searchForm.cust_attr = null;
      this.getCustList();
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.dequ_id;
    },
    // 分页查询
    changePageSearch(val) {
      this.searchForm.page_no = val;
      this.getCustList();
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    cellDbclick(row) {
      this.$emit('childDequ', row);
    },
    getAttr() {
      get(optnAPI.getOptnByPermId, {
        perm_id: 10006
      })
        .then(res => {
          if (res.data.code === 0) {
            this.attr = res.data.data.form.optn_cntt_list;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}

.vd_mrt {
  margin-top: 20px;
}

.vd_mar15 {
  margin: 15px 0;
}

.vd_col_font {
  font-size: 16px;
}
</style>
