<template>
  <div :class="tableRowKey">
    <el-form>
      <el-table
        border
        stripe
        ref="multiTable"
        :data="tableData"
        :row-key="row => row.index + tableRowKey"
        :key="tableKey"
        v-bind="$attrs"
        v-on="$listeners"
        :header-cell-style="headerCellStyle"
        :cell-style="cellStyle"
        :row-style="rowStyle"
        @select="tableSelect"
        @selection-change="tableSelectionChange"
        @select-all="tableSelectAll"
        @row-dblclick="dbClickJp"
        @sort-change="sortChange"
        @cell-click="cellClick"
      >
        <!--表格追加一行具名插槽-->
        <template v-if="$slots.append" slot="append">
          <slot name="append"></slot>
        </template>
        <template v-for="(item, index) in tableColumn">
          <el-table-column
            v-if="['selection', 'index'].includes(item.prop)"
            :key="`${item.prop}-if`"
            v-bind="item"
            :width="item.labelWidth ? item.labelWidth : item.label ? item.label.length * 20 + 30 : 50"
            align="center"
            :column-key="`columnKey${index}`"
          >
            <template v-slot:header="scope">
              <span v-if="$scopedSlots[`header-${item.prop}`]">
                <slot :name="`header-${item.prop}`" v-bind="scope" />
              </span>
              <span v-else>{{ scope.column.label }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :column-key="`columnKey${index}`"
            :key="item.prop"
            v-bind="item"
            :sortable="item.sortable === false ? false : 'custom'"
            :width="
              item.widthAuto
                ? item.widthAuto
                : item.labelWidth
                ? item.labelWidth
                : item[`sum_${item.prop}`] && item[`sum_${item.prop}`].toString().length > item.label.length * 2
                ? item[`sum_${item.prop}`].toString().length * 10
                : item.label.length * 1.3 * 20
            "
            :fixed="item.fixed ? item.fixed : false"
            :align="item.align ? item.align : 'center'"
            :show-overflow-tooltip="item.overflowTooltip ? item.overflowTooltip : false"
          >
            <template v-slot:header="scope">
              <div v-show="needSummation" :class="`summation ${item[`sum_${item.prop}`] ? 'summationBorderBottom' : ''}`">
                {{ item[`sum_${item.prop}`] }}
              </div>
              <div class="daCenter">
                <span>{{ scope.column.label }}</span>
              </div>
              <div @click="handleClick" v-if="needSearch">
                <div v-if="$scopedSlots[`header-${item.prop}`]">
                  <slot :name="`header-${item.prop}`" v-bind="searchForm" />
                </div>
                <div v-else>
                  <el-date-picker
                    v-if="item.itemType === 'date'"
                    v-model="searchForm[item.prop]"
                    show-word-limit
                    type="date"
                    size="mini"
                    clearable
                    placeholder="请选择"
                    @change="getData"
                  >
                  </el-date-picker>
                  <el-input
                    v-if="item.itemType === 'input'"
                    size="mini"
                    :disabled="item.input === false"
                    v-model.trim="searchForm[item.prop]"
                    :placeholder="item.input === false ? '' : '请输入'"
                    clearable
                    @clear="getData"
                    @keyup.enter.native="getData"
                  />
                  <el-select
                    v-if="item.itemType === 'select'"
                    :multiple="item.multiple"
                    :clearable="item.clearable"
                    size="mini"
                    :multiple-limit="item.multipleLimit"
                    :name="item.name"
                    :placeholder="item.placeholder"
                    :allow-create="item.allowCreate"
                    :disabled="item.disabled"
                    :filter-method="item.filterMethod"
                    :filterable="item.filterable"
                    :loading-text="item.loadingText"
                    :no-data-text="item.noDataText"
                    :popper-class="item.popperClass"
                    :no-match-text="item.noMatchText"
                    :loading="item.loading"
                    :remote="item.remote"
                    :remote-method="item.remoteMethod"
                    v-model="searchForm[item.prop]"
                    clearable
                    @change="getData"
                    @visible-change="typeof item.visibleChange === 'function' ? item.visibleChange($event) : () => {}"
                    @remove-tag="typeof item.removeTag === 'function' ? item.removeTag($event) : () => {}"
                  >
                    <el-option
                      v-for="item in item.options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      :disabled="item.disabled"
                      :style="selectOptionStyle"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </template>
            <template v-slot="scope">
              <span v-if="$scopedSlots[item.prop]">
                <slot :name="item.prop" v-bind="scope" />
              </span>
              <div v-else-if="item.subItem">
                <el-form-item label-width="0" :prop="`tableData.${scope.$index}.${item.prop}`">
                  <el-input
                    v-if="item.subItem.type === 'input'"
                    v-model="scope.row[item.prop]"
                    :disabled="item.subItem.input === false"
                    :placeholder="item.subItem.input === false ? '' : '请输入'"
                    :maxlength="item.subItem.subMaxLength"
                    size="mini"
                    clearable
                  />
                  <div v-if="item.subItem.type === 'image'">
                    <el-popover :ref="`image${scope.$index}${item.prop}`" placement="right" width="500" trigger="hover">
                      <el-image
                        slot="reference"
                        :src="typeof item.subItem.thumbnail === 'function' ? item.subItem.thumbnail(scope.row) : item.subItem.thumbnail"
                      />
                      <el-image :src="typeof item.subItem.image === 'function' ? item.subItem.image(scope.row) : item.subItem.image" />
                    </el-popover>
                  </div>
                  <div v-if="item.subItem.type === 'image2'">
                    <el-image
                      lazy
                      style="height: 75px"
                      :preview-src-list="[item.subItem.image(scope.row)]"
                      :src="typeof item.subItem.thumbnail === 'function' ? item.subItem.thumbnail(scope.row) : item.subItem.thumbnail"
                    />
                  </div>
                </el-form-item>
              </div>
              <span v-else>
                {{
                  item.formatter
                    ? item.needOtherColumn
                      ? item.formatter(scope.row)
                      : item.formatter(scope.row[item.prop])
                    : scope.row[item.prop]
                    ? scope.row[item.prop]
                    : '暂无'
                }}
              </span>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <pubPagination
        v-if="needPagination"
        v-bind="$attrs"
        v-on="$listeners"
        :totalPage="totalPage"
        @changePageSearch="changePageSearch"
        ref="pubPagination"
      />
    </el-form>
  </div>
</template>

<script>
import PubPagination from '@/components/common/pubPagination.vue';
import Sortable from 'sortablejs';
import ImgPopover from '@/views/component/imgPopover.vue';

export default {
  name: 'SearchTable',
  components: { PubPagination, Sortable, ImgPopover },
  props: {
    columns: { type: Array, default: () => [] },
    pagination: { type: Object, default: () => ({}) },
    data: { type: Array, default: () => [] },
    loadFlag: { type: Boolean, default: false },
    needPagination: { type: Boolean, default: true },
    needSummation: { type: Boolean, default: false },
    needSearch: { type: Boolean, default: true },
    rowMove: { type: Boolean, default: false },
    columnMove: { type: Boolean, default: true },
    dbClickJp: { type: Function, default: () => {} },
    rowKey: { type: Function || String, default: null },
    rowStyle: { type: Function || Object, default: ({ row, rowIndex }) => (row.index = rowIndex) },
    tableSelect: { type: Function, default: () => null },
    tableSelectionChange: { type: Function, default: () => null },
    tableSelectAll: { type: Function, default: () => null },
    totalPage: { type: Number, default: 0 },
    getTableData: { type: Function, default: () => null },
    selectOptionStyle: String,
    tableRowKey: String,
    headerCellStyle: {
      type: Function || Object,
      default: () => {
        return {
          'background-color': '#f5f6f7',
          'font-size': '14px'
        };
      }
    },
    cellStyle: {
      type: Function || Object,
      default: () => {
        return {
          'font-size': '14px'
        };
      }
    }
  },
  computed: {
    tableData() {
      return this.data ?? [];
    },
    tableColumn() {
      return this.columns ?? [];
    }
  },
  watch: {
    tableColumn(newValue, oldValue) {
      console.warn(newValue);
    }
  },
  data() {
    return {
      searchForm: {
        page_no: 1
      },
      tableKey: ''
    };
  },
  async mounted() {
    await this.positionToRepair();
    await this.rowDrop();
    await this.columnDrop();
  },
  methods: {
    /**
     * 排序图标位置修复
     */
    positionToRepair() {
      if (this.columnMove === false) return;
      let elements = document.getElementsByClassName('caret-wrapper');
      for (let i = 0; i < elements.length; i++) {
        elements[i].parentNode.children[1].appendChild(elements[i]);
      }
    },
    /**
     * 行拖拽
     */
    rowDrop() {
      if (this.rowMove === false) return;
      // 要侦听拖拽响应的DOM对象
      const tbody = document.querySelector(`.${this.tableRowKey} > .el-table__body-wrapper tbody`);
      const _this = this;
      Sortable.create(tbody, {
        // 结束拖拽后的回调函数
        onEnd({ newIndex, oldIndex }) {
          const currentRow = _this.tableData.splice(oldIndex, 1)[0];
          _this.tableData.splice(newIndex, 0, currentRow);
          _this.reDrawTable();
        }
      });
    },
    /**
     * 列拖拽
     */
    async columnDrop() {
      if (this.columnMove === false) return;
      const _this = this;
      const wrapperTr = document.querySelector(`.${this.tableRowKey} .el-table__header-wrapper tr`);
      await Sortable.create(wrapperTr, {
        animation: 350,
        delay: 0,
        onEnd: evt => {
          const oldItem = this.tableColumn[evt.oldIndex];
          _this.tableColumn.splice(evt.oldIndex, 1);
          _this.tableColumn.splice(evt.newIndex, 0, oldItem);
          _this.reDrawTable();
        }
      });
      await this.positionToRepair();
    },
    /**
     * 触发表格重绘
     */
    reDrawTable() {
      this.tableKey = Math.random();
      this.$nextTick(() => {
        this.rowDrop();
        this.columnDrop();
      });
    },
    /**
     * 排序参数
     * @param order 排序正倒序
     * @param prop 指定排序字段
     */
    sortChange({ order, prop }) {
      if (!order) {
        this.searchForm.column = '';
        this.searchForm.order = '';
        return;
      }
      this.searchForm.column = prop;
      this.searchForm.order = order.substring(0, order.indexOf('ending'));
      this.getData();
    },
    handleClick(e) {
      // 阻断冒泡上级元素事件
      e.cancelBubble = true;
    },
    /**
     * 获取数据
     */
    getData() {
      if (this.needPagination) {
        this.$refs.pubPagination.currentPage = 1;
        this.searchForm.page_no = 1;
      }
      this.$emit('getTableData');
    },
    /**
     * 重置表单
     */
    resetFields() {
      if (this.needPagination) {
        this.$refs.pubPagination.currentPage = 1;
        this.searchForm = { page_no: 1 };
        return;
      }
      this.searchForm = {};
    },
    /**
     * 返回表单数据
     * @return {Object} searchForm
     */
    getSearchFrom() {
      return this.searchForm;
    },
    /**
     * 格子事件 点击复制
     * @param row
     * @param column
     * @param cell
     * @param event
     */
    cellClick(row, column, cell, event) {
      // 该对象只能在localhost及https环境下使用
      navigator.clipboard.writeText(event.target.innerText);
    },
    changePageSearch(val) {
      this.searchForm.page_no = val;
      this.$emit('getTableData');
    }
  }
};
</script>

<style scoped>
.summation {
  height: 20px;
  text-align: right;
  padding-right: 10px;
}
.summationBorderBottom {
  border-bottom: 1px solid #494a4d;
}
.daCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
}
</style>
