<template>
  <div class="vg_wrapper">
    <div class="vd_search_group">
      <el-form :inline="true" size="min" :model="searchForm" label-width="120px" ref="searchForm" @keyup.enter.native="getDesiList()">
        <el-row>
          <el-col :md="8">
            <el-form-item label="委托设计单号:" prop="dequ_no">
              <el-input v-model="searchForm.dequ_no" size="small" clearable placeholder="请填写委托设计单号"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="设计单号:" prop="desi_no">
              <el-input size="small" v-model.trim="searchForm.desi_no" clearable placeholder="请填写设计单号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="设计人:">
              <el-select v-model="searchForm.desi_stff_id" clearable size="small" filterable>
                <el-option v-for="item in users" :key="item.stff_id" :value="item.stff_id" :label="item.stff_name"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="8">
            <el-form-item label="我司货号:" prop="prod_no">
              <el-input size="small" v-model.trim="searchForm.prod_no" clearable placeholder="请填写我司货号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item label="产品名称:" prop="prod_name">
              <el-input v-model="searchForm.prod_name" size="small" clearable placeholder="请填写产品名称"> </el-input>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getDequsNow()" class="vg_ml_16">查询 </el-button>
              <el-button size="small" type="info" icon="el-icon-refresh" @click="getDequsNowO()" class="vg_ml_16">刷新 </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-row class="vd_mar15">
      <el-col :md="16">
        <el-button size="mini" type="danger" @click="cancel">取消选择</el-button>
        <el-button size="mini" type="primary" @click="confirmIn()">确认选择</el-button>
      </el-col>
    </el-row>
    <el-row>
      <div><i class="el-icon-info vg_mr_8"></i>{{ title }}</div>
    </el-row>
    <el-row class="vd_mrt">
      <el-col :md="24">
        <el-table
          ref="multiTable"
          :data="custList"
          @selection-change="handleSelectionChange"
          :row-key="getRowKey"
          border
          v-loading="loading"
        >
          <el-table-column type="selection" width="48" align="center" :reserve-selection="true" />
          <el-table-column label="委托设计单号" :show-overflow-tooltip="true" prop="dequ_no" align="center" />
          <el-table-column label="设计单号" prop="desi_no" align="center" />
          <el-table-column label="我司货号" prop="prod_no" align="center" :show-overflow-tooltip="true" />

          <el-table-column label="产品名称" prop="prod_name" align="center" :show-overflow-tooltip="true" />
          <el-table-column label="产品规格" prop="prod_spec" align="center" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="设计人" align="center">
            <template slot-scope="scope">{{ getUser(scope.row.desi_stff_id) }}</template>
          </el-table-column>
          <el-table-column label="设计日期" align="center">
            <template slot-scope="scope">{{ helper.toTimeDay(scope.row.desi_date) }}</template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <pubPagination ref="pubPagination" :totalPage="totalPage" @changePageSearch="changePageSearch"></pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { custAPI } from '@api/modules/cust';
import pubPagination from '@/components/common/pubPagination';
import helper from '@assets/js/helper.js';
import { optnAPI } from '@/api/modules/optn';
import { desiAPI } from '../../../../../api/modules/desi';
import { stffAPI } from '@/api/modules/staff';

export default {
  name: 'DequImport',
  components: {
    pubPagination
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        page_no: 1,
        desi_no: null, //设计单号
        desi_stff_id: null, //设计人
        dequ_no: null, //委托设计单号
        prod_name: null,
        prod_no: null
      },
      totalPage: 0,
      btn: {},
      loading: true,
      dequStffList: [],
      custList: [],
      multiSelection: [],
      currentPage: 1,
      attr: [],
      users: [],
      title: '提示：相同数据仅限导入一条'
    };
  },
  created() {
    this.initData();
    this.getUsers();
    // this.getAttr()
  },
  filters: {
    formatDequPrice(row) {
      return helper.haveFour(row);
    }
  },
  methods: {
    initData() {
      this.getDesiList();
    },
    getDesiList() {
      get(desiAPI.get_all_desi_v1, {
        page_no: this.searchForm.page_no,
        desi_no: this.searchForm.desi_no,
        desi_stff_id: this.searchForm.desi_stff_id,
        dequ_no: this.searchForm.dequ_no,
        prod_no: this.searchForm.prod_no,
        status: 2,
        prod_name: this.searchForm.prod_name
      })
        .then(res => {
          if (res.data.code === 0) {
            this.custList = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            this.loading = false;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 查询方法
    getDequsNow() {
      this.$refs.pubPagination.currentPage = 1;
      this.loading = true;
      this.searchForm.page_no = 1;
      this.getDesiList();
    },
    // 刷新
    getDequsNowO() {
      this.$refs.pubPagination.currentPage = 1;
      this.loading = true;
      this.searchForm.page_no = 1;
      this.searchForm.desi_stff_id = null;
      this.searchForm.desi_no = null;
      this.searchForm.dequ_no = null;
      this.searchForm.prod_name = null;
      this.searchForm.prod_no = null;
      this.getDesiList();
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.desi_id;
    },
    // 多选
    handleSelectionChange(val, w) {
      this.multiSelection = val;
    },
    // 分页查询
    changePageSearch(val) {
      this.searchForm.page_no = val;
      this.getDesiList();
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 取消选择
    cancel() {
      this.clear();
      this.$emit('pldequChildChanel', false);
    },
    // 确认选择
    confirmIn() {
      if (this.multiSelection.length > 0) {
        this.$emit('childDequ', JSON.parse(JSON.stringify(this.multiSelection)));
        this.$nextTick(() => {
          this.$refs.multiTable.clearSelection();
        });
      } else {
        this.$message({
          message: '至少选择一条信息',
          type: 'warning'
        });
      }
    },
    getUsers() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.users = res.data.data;
          }
        })
        .catch(err => {
          this.$message.error(err.data.msg);
        });
    },
    getAttr() {
      get(optnAPI.getOptnByPermId, {
        perm_id: 10006
      })
        .then(res => {
          if (res.data.code === 0) {
            this.attr = res.data.data.form.optn_cntt_list;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    getUser(status) {
      let str = '';
      this.users.forEach(item => {
        if (status === item.stff_id) {
          str = item.stff_name;
        }
      });
      return str;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}

.vd_mrt {
  margin-top: 20px;
}

.vd_mar15 {
  margin: 15px 0;
}

.vd_col_font {
  font-size: 16px;
}

::v-deep .el-form-item__label {
  display: inline-block !important;
}
</style>
