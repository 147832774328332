import { render, staticRenderFns } from "./RequAddmain.vue?vue&type=template&id=b6af39a4&scoped=true&"
import script from "./RequAddmain.vue?vue&type=script&lang=js&"
export * from "./RequAddmain.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6af39a4",
  null
  
)

export default component.exports