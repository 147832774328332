<template>
  <el-tabs :value="'first'" class="addAndEdit" type="border-card">
    <el-tab-pane :key="'first'" label="单据信息" name="first">
      <RequAddmain />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import RequAddmain from './TabChild/RequAddmain.vue';

export default {
  name: 'requAdd',
  components: {
    RequAddmain
  }
};
</script>

<style scoped>
.addAndEdit > ::v-deep .el-tabs__content {
  height: calc(100vh - 179px);
  overflow-y: auto;
  padding-top: 0;
}
</style>
