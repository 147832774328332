<template>
  <div>
    <div style="margin: 16px 0px"></div>
    <el-row>
      <el-col>
        <el-button size="small" type="primary" @click="addSubTableRow">新增</el-button>
        <el-button size="small" type="danger" @click="clickDel">删除</el-button>
        <el-button v-if="isCopy" :disabled="false" size="small" type="warning" @click="copySelectedColumnsToClipboard"> 辅料部专用复制</el-button>
      </el-col>
    </el-row>
    <el-table
      :data="dataForm.dataList2"
      style="width: 100%"
      :height="400"
      border
      @selection-change="handleSelectionChange"
      ref="contentTableArtNo"
      class="vg_mt_16"
      v-if="dataForm.requ_type !== '2'"
    >
      <el-table-column type="selection" align="center"></el-table-column>
      <el-table-column :fixed="fixed" width="1" class="vd_hidden"></el-table-column>
      <el-table-column label="序号" width="50" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="产品图片" min-width="220px" prop="imge_url">
        <template #header>
          <span style="color: red">产品图片</span>
        </template>
        <template v-slot="scope">
          <el-form-item
            :prop="'dataList2.' + scope.$index + '.imge_url'"
            label="产品图片"
            label-width="0"
            :rules="[{ required: Number(dataForm.requ_type) === 2 }]"
          >
            <div style="display: flex">
              <el-image :preview-src-list="[scope.row.imge_url]" :src="scope.row.imge_url" class="flexHV" style="height: 80px; width: 80px">
                <span slot="error" v-html="'暂无'"></span>
              </el-image>
              <el-upload
                :ref="`uploadMultiple${scope.$index}`"
                :action="uploadUrl"
                :before-upload="beforeAvatarUpload"
                :data="dataBody"
                :on-success="res => handleAvatarSuccess(res, scope.row)"
                :show-file-list="false"
              >
                <el-link class="vg_cursor" type="primary">点击上传</el-link>
              </el-upload>
              <div id="preview" class="flexHV" @paste="e => handlePaste(e, scope.$index)">
                <el-link slot="trigger" class="vg_cursor" type="primary">粘贴</el-link>
              </div>
              <el-link
                :underline="false"
                class="vg_cursor"
                type="danger"
                @click="
                  scope.row.imge_url = '';
                  scope.row.imge_id = '';
                "
                >删除
              </el-link>
            </div>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column align="center" label="客户货号" min-width="140px" prop="prod_cust_no">
        <template v-slot="scope">
          <el-form-item :prop="'dataList2.' + scope.$index + '.prod_cust_no'" :rules="[{ required: true, message: ' ' }]" label="客户货号" label-width="0">
            <el-input v-model="scope.row.prod_cust_no" maxlength="20" show-word-limit size="small"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="样品数量" prop="requ_smpl_num" align="center" min-width="140px">
        <template #header>
          <span>样品数量</span>
        </template>
        <template v-slot="scope">
          <el-form-item label="样品数量" :prop="'dataList2.' + scope.$index + '.requ_smpl_num'" :rules="[{ required: true }]">
            <el-input
              class="price"
              v-model="dataForm.dataList2[scope.$index].requ_smpl_num"
              maxlength="7"
              show-word-limit
              @input="val => (dataForm.dataList2[scope.$index].requ_smpl_num = keepNumber(val))"
              size="small"
              @change="numChange"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="样品单位" prop="prod_unit" align="center" min-width="110px">
        <template v-slot="scope">
          <el-form-item label="样品单位" label-width="0" :prop="'dataList2.' + scope.$index + '.prod_unit'">
            <div style="display: flex">
              <el-select v-model="dataForm.dataList2[scope.$index].prod_unit" size="small">
                <el-option v-for="item in unitLIst" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
              <div style="display: flex; flex-direction: column; justify-content: center">
                <el-tooltip effect="dark" content="复制" placement="top">
                  <el-link
                    class="vg_cursor"
                    :underline="false"
                    icon="el-icon-document-copy"
                    @click="copyCell(`prod_unit[copyDataFlag]${scope.row.prod_unit}[copyDataFlag]${scope.$index}[copyDataFlag]${typeof scope.row.prod_unit}`)"
                  ></el-link>
                </el-tooltip>
                <span style="height: 9px"></span>
                <el-tooltip effect="dark" content="粘贴" placement="right">
                  <el-link class="vg_cursor" :underline="false" icon="el-icon-finished" @click="pasteCell('prod_unit', scope.row, scope.$index)"></el-link>
                </el-tooltip>
              </div>
            </div>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="样品类型" prop="requ_smpl_type" align="center" min-width="120px">
        <template #header>
          <span style="color: red">样品类型</span>
        </template>
        <template v-slot="scope">
          <el-form-item label="样品类型" :prop="'dataList2.' + scope.$index + '.requ_smpl_type'" :rules="[{ required: true }]">
            <div style="display: flex">
              <el-select v-model="dataForm.dataList2[scope.$index].requ_smpl_type" size="small">
                <el-option v-for="item in typeList" :key="item.value" :value="item.value" :label="item.label"></el-option>
              </el-select>
              <div style="display: flex; flex-direction: column; justify-content: center">
                <el-tooltip effect="dark" content="复制" placement="top">
                  <el-link
                    class="vg_cursor"
                    :underline="false"
                    icon="el-icon-document-copy"
                    @click="
                      copyCell(
                        `requ_smpl_type[copyDataFlag]${scope.row.requ_smpl_type}[copyDataFlag]${scope.$index}[copyDataFlag]${typeof scope.row.requ_smpl_type}`
                      )
                    "
                  ></el-link>
                </el-tooltip>
                <span style="height: 9px"></span>
                <el-tooltip effect="dark" content="粘贴" placement="right">
                  <el-link class="vg_cursor" :underline="false" icon="el-icon-finished" @click="pasteCell('requ_smpl_type', scope.row, scope.$index)"></el-link>
                </el-tooltip>
              </div>
            </div>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="样品要求" align="center" prop="sample_ements" min-width="140px">
        <template #header>
          <span>样品要求</span>
        </template>
        <template v-slot="scope">
          <el-form-item label="样品要求" label-width="0" :prop="'dataList2.' + scope.$index + '.sample_ements'">
            <el-input maxlength="10" show-word-limit size="small" v-model="scope.row.sample_ements"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="委托类型" prop="requ_smpl_quot" align="center" min-width="140px">
        <template #header>
          <span style="color: red">委托类型</span>
        </template>
        <template v-slot="scope">
          <el-form-item
            label="委托类型"
            label-width="0"
            :prop="'dataList2.' + scope.$index + '.requ_smpl_quot'"
            size="small"
            @change="isShowRequSmplPedate(scope.$index)"
            :rules="[{ required: true }]"
          >
            <CopyPasteCell
              :ref="`CopyPasteCell${scope.$index}`"
              :copy-data="scope.row.requ_smpl_quot"
              :index="scope.$index"
              :column="'requ_smpl_quot'"
              :is-show="false"
              :type="typeof scope.row.requ_smpl_quot"
              @changeData="data => $refs[`CopyPasteCell${scope.$index}`].changeData({ list: dataForm.dataList2, ...data })"
            >
              <template v-slot:elForm>
                <el-select v-model="dataForm.dataList2[scope.$index].requ_smpl_quot">
                  <el-option v-for="item in quotList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                </el-select>
              </template>
            </CopyPasteCell>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="要求交期" prop="requ_smpl_pedate" align="center" min-width="150px">
        <template #header>
          <span style="color: red">要求交期</span>
        </template>
        <template v-slot="scope">
          <el-form-item label="要求交样日期" label-width="0" :prop="'dataList2.' + scope.$index + '.requ_smpl_pedate'" :rules="[{ required: pedataRules }]">
            <div style="display: flex">
              <el-date-picker v-model="dataForm.dataList2[scope.$index].requ_smpl_pedate" size="small" type="date" placeholder="选择日期" clearable>
              </el-date-picker>
              <div style="display: flex; flex-direction: column; justify-content: center">
                <el-tooltip effect="dark" content="复制" placement="top">
                  <el-link
                    class="vg_cursor"
                    :underline="false"
                    icon="el-icon-document-copy"
                    @click="
                      copyCell(
                        `requ_smpl_pedate[copyDataFlag]${scope.row.requ_smpl_pedate}[copyDataFlag]${scope.$index}[copyDataFlag]${typeof scope.row
                          .requ_smpl_pedate}`
                      )
                    "
                  ></el-link>
                </el-tooltip>
                <span style="height: 9px"></span>
                <el-tooltip effect="dark" content="粘贴" placement="right">
                  <el-link
                    class="vg_cursor"
                    :underline="false"
                    icon="el-icon-finished"
                    @click="pasteCell('requ_smpl_pedate', scope.row, scope.$index)"
                  ></el-link>
                </el-tooltip>
              </div>
            </div>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column align="center" label="样品编号" min-width="140px" prop="requ_nsmpl_no">
        <template v-slot="scope">
          <el-form-item :prop="'dataList2.' + scope.$index + '.requ_nsmpl_no'" label="样品编号" label-width="0">
            <el-input v-model="scope.row.requ_nsmpl_no" disabled show-word-limit size="small"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column align="center" label="产品名称" min-width="140px" prop="prod_name">
        <template #header>
          <span style="color: red">产品名称</span>
        </template>
        <template v-slot="scope">
          <el-form-item :prop="'dataList2.' + scope.$index + '.prod_name'" :rules="[{ required: true }]" label="产品名称" label-width="0">
            <el-input maxlength="20" show-word-limit size="small" v-model="scope.row.prod_name"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="包装要求" prop="prod_content" align="center" min-width="120px">
        <template v-slot="scope">
          <el-form-item>
            <el-input
              v-model="dataForm.dataList2[scope.$index].prod_content"
              type="textarea"
              maxlength="50"
              size="mini"
              :autosize="{ minRows: 1.1, maxRows: 1.1 }"
              class="textarea"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="采购报价" prop="purch_price" align="center" min-width="140px">
        <template v-slot="scope">
          <el-form-item label="采购报价" :prop="'dataList2.' + scope.$index + '.purch_price'">
            <el-input class="price" v-model="dataForm.dataList2[scope.$index].purch_price" maxlength="11" show-word-limit size="small"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="打样接收人" prop="requ_smpl_taker" align="center" min-width="120px">
        <template v-slot="scope">
          <CopyPasteCell
            :ref="`CopyPasteCell${scope.$index}`"
            :column="'requ_smpl_taker'"
            :copy-data="scope.row.requ_smpl_taker"
            :index="scope.$index"
            :is-show="false"
            :type="typeof scope.row.requ_smpl_taker"
            @changeData="data => $refs[`CopyPasteCell${scope.$index}`].changeData({ list: dataForm.dataList2, ...data })"
          >
            <template v-slot:elForm>
              <el-form-item>
                <div class="vg_dis_flex">
                  <el-select v-model="dataForm.dataList2[scope.$index].requ_smpl_taker" clearable size="small">
                    <el-option v-for="item in userList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                  <el-tag v-if="dataForm.dataList2[scope.$index].taker_push_state === 2" type="success">已接收</el-tag>
                  <el-tag v-if="dataForm.dataList2[scope.$index].taker_push_state === 1" type="info">未接收</el-tag>
                </div>
              </el-form-item>
            </template>
          </CopyPasteCell>
        </template>
      </el-table-column>
      <el-table-column label="报价接收人" prop="requ_smpl_quoter" align="center" min-width="120px">
        <template v-slot="scope">
          <CopyPasteCell
            :ref="`CopyPasteCell${scope.$index}`"
            :column="'requ_smpl_quoter'"
            :copy-data="scope.row.requ_smpl_quoter"
            :index="scope.$index"
            :is-show="false"
            :type="typeof scope.row.requ_smpl_quoter"
            @changeData="data => $refs[`CopyPasteCell${scope.$index}`].changeData({ list: dataForm.dataList2, ...data })"
          >
            <template v-slot:elForm>
              <el-form-item>
                <div class="vg_dis_flex">
                  <el-select v-model="dataForm.dataList2[scope.$index].requ_smpl_quoter" clearable placeholder="请选择报价接收人" size="small">
                    <el-option v-for="item in userList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                  <el-tag v-if="dataForm.dataList2[scope.$index].quoter_push_state === 2" type="success">已接收</el-tag>
                  <el-tag v-if="dataForm.dataList2[scope.$index].quoter_push_state === 1" type="info">未接收</el-tag>
                </div>
              </el-form-item>
            </template>
          </CopyPasteCell>
        </template>
      </el-table-column>
      <el-table-column label="核价接收人" prop="requ_smpl_pricer" align="center" min-width="120px">
        <template v-slot="scope">
          <CopyPasteCell
            :ref="`CopyPasteCell${scope.$index}`"
            :column="'requ_smpl_pricer'"
            :copy-data="scope.row.requ_smpl_pricer"
            :index="scope.$index"
            :is-show="false"
            :type="typeof scope.row.requ_smpl_pricer"
            @changeData="data => $refs[`CopyPasteCell${scope.$index}`].changeData({ list: dataForm.dataList2, ...data })"
          >
            <template v-slot:elForm>
              <el-form-item>
                <el-select v-model="dataForm.dataList2[scope.$index].requ_smpl_pricer" clearable size="small">
                  <el-option v-for="item in userList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </CopyPasteCell>
        </template>
      </el-table-column>
    </el-table>
    <el-table
      :data="dataForm.dataList2"
      style="width: 100%"
      :height="400"
      border
      @selection-change="handleSelectionChange"
      ref="contentTableArtNo"
      class="vg_mt_16"
      v-if="dataForm.requ_type === '2'"
    >
      <el-table-column type="selection" align="center"></el-table-column>
      <el-table-column label="序号" width="50" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column :fixed="fixed" width="1" class="vd_hidden"></el-table-column>
      <el-table-column align="center" label="客户货号" min-width="140px" prop="prod_cust_no">
        <template v-slot="scope">
          <el-form-item :prop="'dataList2.' + scope.$index + '.prod_cust_no'" :rules="[{ required: true, message: ' ' }]" label="客户货号" label-width="0">
            <el-input v-model="scope.row.prod_cust_no" maxlength="20" show-word-limit size="small"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="样品数量" prop="requ_smpl_num" align="center" min-width="140px">
        <template #header>
          <span>样品数量</span>
        </template>
        <template v-slot="scope">
          <el-form-item label="样品数量" :prop="'dataList2.' + scope.$index + '.requ_smpl_num'" :rules="[{ required: true }]">
            <el-input
              class="price"
              v-model="dataForm.dataList2[scope.$index].requ_smpl_num"
              maxlength="7"
              show-word-limit
              @input="val => (dataForm.dataList2[scope.$index].requ_smpl_num = keepNumber(val))"
              size="small"
              @change="numChange"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="样品单位" prop="prod_unit" align="center" min-width="110px">
        <template v-slot="scope">
          <el-form-item label="样品单位" label-width="0" :prop="'dataList2.' + scope.$index + '.prod_unit'">
            <div style="display: flex">
              <el-select v-model="dataForm.dataList2[scope.$index].prod_unit" size="small">
                <el-option v-for="item in unitLIst" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
              <div style="display: flex; flex-direction: column; justify-content: center">
                <el-tooltip effect="dark" content="复制" placement="top">
                  <el-link
                    class="vg_cursor"
                    :underline="false"
                    icon="el-icon-document-copy"
                    @click="copyCell(`prod_unit[copyDataFlag]${scope.row.prod_unit}[copyDataFlag]${scope.$index}[copyDataFlag]${typeof scope.row.prod_unit}`)"
                  ></el-link>
                </el-tooltip>
                <span style="height: 9px"></span>
                <el-tooltip effect="dark" content="粘贴" placement="right">
                  <el-link class="vg_cursor" :underline="false" icon="el-icon-finished" @click="pasteCell('prod_unit', scope.row, scope.$index)"></el-link>
                </el-tooltip>
              </div>
            </div>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="样品类型" prop="requ_smpl_type" align="center" min-width="120px">
        <template #header>
          <span style="color: red">样品类型</span>
        </template>
        <template v-slot="scope">
          <el-form-item label="样品类型" :prop="'dataList2.' + scope.$index + '.requ_smpl_type'" :rules="[{ required: true }]">
            <div style="display: flex">
              <el-select v-model="dataForm.dataList2[scope.$index].requ_smpl_type" size="small">
                <el-option v-for="item in typeList" :key="item.value" :value="item.value" :label="item.label"></el-option>
              </el-select>
              <div style="display: flex; flex-direction: column; justify-content: center">
                <el-tooltip effect="dark" content="复制" placement="top">
                  <el-link
                    class="vg_cursor"
                    :underline="false"
                    icon="el-icon-document-copy"
                    @click="
                      copyCell(
                        `requ_smpl_type[copyDataFlag]${scope.row.requ_smpl_type}[copyDataFlag]${scope.$index}[copyDataFlag]${typeof scope.row.requ_smpl_type}`
                      )
                    "
                  ></el-link>
                </el-tooltip>
                <span style="height: 9px"></span>
                <el-tooltip effect="dark" content="粘贴" placement="right">
                  <el-link class="vg_cursor" :underline="false" icon="el-icon-finished" @click="pasteCell('requ_smpl_type', scope.row, scope.$index)"></el-link>
                </el-tooltip>
              </div>
            </div>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="委托类型" prop="requ_smpl_quot" align="center" min-width="140px">
        <template #header>
          <span style="color: red">委托类型</span>
        </template>
        <template v-slot="scope">
          <el-form-item
            label="委托类型"
            label-width="0"
            :prop="'dataList2.' + scope.$index + '.requ_smpl_quot'"
            size="small"
            @change="isShowRequSmplPedate(scope.$index)"
            :rules="[{ required: true }]"
          >
            <CopyPasteCell
              :ref="`CopyPasteCell${scope.$index}`"
              :copy-data="scope.row.requ_smpl_quot"
              :index="scope.$index"
              :column="'requ_smpl_quot'"
              :is-show="false"
              :type="typeof scope.row.requ_smpl_quot"
              @changeData="data => $refs[`CopyPasteCell${scope.$index}`].changeData({ list: dataForm.dataList2, ...data })"
            >
              <template v-slot:elForm>
                <el-select v-model="dataForm.dataList2[scope.$index].requ_smpl_quot">
                  <el-option
                    v-for="item in dataForm.requ_type === 1 ? quotList : quotList1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </template>
            </CopyPasteCell>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="要求交期" prop="requ_smpl_pedate" align="center" min-width="150px">
        <template #header>
          <span style="color: red">要求交期</span>
        </template>
        <template v-slot="scope">
          <el-form-item label="要求交样日期" label-width="0" :prop="'dataList2.' + scope.$index + '.requ_smpl_pedate'" :rules="[{ required: pedataRules }]">
            <div style="display: flex">
              <el-date-picker v-model="dataForm.dataList2[scope.$index].requ_smpl_pedate" size="small" type="date" placeholder="选择日期" clearable>
              </el-date-picker>
              <div style="display: flex; flex-direction: column; justify-content: center">
                <el-tooltip effect="dark" content="复制" placement="top">
                  <el-link
                    class="vg_cursor"
                    :underline="false"
                    icon="el-icon-document-copy"
                    @click="
                      copyCell(
                        `requ_smpl_pedate[copyDataFlag]${scope.row.requ_smpl_pedate}[copyDataFlag]${scope.$index}[copyDataFlag]${typeof scope.row
                          .requ_smpl_pedate}`
                      )
                    "
                  ></el-link>
                </el-tooltip>
                <span style="height: 9px"></span>
                <el-tooltip effect="dark" content="粘贴" placement="right">
                  <el-link
                    class="vg_cursor"
                    :underline="false"
                    icon="el-icon-finished"
                    @click="pasteCell('requ_smpl_pedate', scope.row, scope.$index)"
                  ></el-link>
                </el-tooltip>
              </div>
            </div>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column align="center" label="产品图片" min-width="220px" prop="imge_url">
        <template #header>
          <span style="color: red">产品图片</span>
        </template>
        <template v-slot="scope">
          <el-form-item
            :prop="'dataList2.' + scope.$index + '.imge_url'"
            label="产品图片"
            label-width="0"
            :rules="[{ required: Number(dataForm.requ_type) === 2 }]"
          >
            <div style="display: flex">
              <el-image :preview-src-list="[scope.row.imge_url]" :src="scope.row.imge_url" class="flexHV" style="height: 80px; width: 80px">
                <span slot="error" v-html="'暂无'"></span>
              </el-image>
              <el-upload
                :ref="`uploadMultiple${scope.$index}`"
                :action="uploadUrl"
                :before-upload="beforeAvatarUpload"
                :data="dataBody"
                :on-success="res => handleAvatarSuccess(res, scope.row)"
                :show-file-list="false"
              >
                <el-link class="vg_cursor" type="primary">点击上传</el-link>
              </el-upload>
              <div id="preview" class="flexHV" @paste="e => handlePaste(e, scope.$index)">
                <el-link slot="trigger" class="vg_cursor" type="primary">粘贴</el-link>
              </div>
              <el-link
                :underline="false"
                class="vg_cursor"
                type="danger"
                @click="
                  scope.row.imge_url = '';
                  scope.row.imge_id = '';
                "
                >删除
              </el-link>
            </div>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="材质工艺" align="center" prop="sample_ements" min-width="140px">
        <template #header>
          <span>材质工艺</span>
        </template>
        <template v-slot="scope">
          <el-form-item label="材质工艺" label-width="0" :prop="'dataList2.' + scope.$index + '.sample_ements'">
            <el-input maxlength="10" show-word-limit size="small" v-model="scope.row.sample_ements"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column align="center" label="产品名称" min-width="140px" prop="prod_name">
        <template #header>
          <span style="color: red">产品名称</span>
        </template>
        <template v-slot="scope">
          <el-form-item :prop="'dataList2.' + scope.$index + '.prod_name'" :rules="[{ required: true }]" label="产品名称" label-width="0">
            <el-input maxlength="20" show-word-limit size="small" v-model="scope.row.prod_name"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column align="center" label="样品编号" min-width="140px" prop="requ_nsmpl_no">
        <template v-slot="scope">
          <el-form-item :prop="'dataList2.' + scope.$index + '.requ_nsmpl_no'" label="样品编号" label-width="0">
            <el-input v-model="scope.row.requ_nsmpl_no" disabled show-word-limit size="small"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="包装要求" prop="prod_content" align="center" min-width="120px">
        <template v-slot="scope">
          <el-form-item>
            <el-input
              v-model="dataForm.dataList2[scope.$index].prod_content"
              type="textarea"
              maxlength="50"
              size="mini"
              :autosize="{ minRows: 1.1, maxRows: 1.1 }"
              class="textarea"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="采购报价" prop="purch_price" align="center" min-width="140px">
        <template v-slot="scope">
          <el-form-item label="采购报价" :prop="'dataList2.' + scope.$index + '.purch_price'">
            <el-input class="price" v-model="dataForm.dataList2[scope.$index].purch_price" maxlength="11" show-word-limit size="small"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="打样接收人" prop="requ_smpl_taker" align="center" min-width="120px">
        <template v-slot="scope">
          <CopyPasteCell
            :ref="`CopyPasteCell${scope.$index}`"
            :column="'requ_smpl_taker'"
            :copy-data="scope.row.requ_smpl_taker"
            :index="scope.$index"
            :is-show="false"
            :type="typeof scope.row.requ_smpl_taker"
            @changeData="data => $refs[`CopyPasteCell${scope.$index}`].changeData({ list: dataForm.dataList2, ...data })"
          >
            <template v-slot:elForm>
              <el-form-item>
                <div class="vg_dis_flex">
                  <el-select v-model="dataForm.dataList2[scope.$index].requ_smpl_taker" clearable size="small">
                    <el-option v-for="item in userList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                  <el-tag v-if="dataForm.dataList2[scope.$index].taker_push_state === 2" type="success">已接收</el-tag>
                  <el-tag v-if="dataForm.dataList2[scope.$index].taker_push_state === 1" type="info">未接收</el-tag>
                </div>
              </el-form-item>
            </template>
          </CopyPasteCell>
        </template>
      </el-table-column>
      <el-table-column label="报价接收人" prop="requ_smpl_quoter" align="center" min-width="120px">
        <template v-slot="scope">
          <CopyPasteCell
            :ref="`CopyPasteCell${scope.$index}`"
            :column="'requ_smpl_quoter'"
            :copy-data="scope.row.requ_smpl_quoter"
            :index="scope.$index"
            :is-show="false"
            :type="typeof scope.row.requ_smpl_quoter"
            @changeData="data => $refs[`CopyPasteCell${scope.$index}`].changeData({ list: dataForm.dataList2, ...data })"
          >
            <template v-slot:elForm>
              <el-form-item>
                <div class="vg_dis_flex">
                  <el-select v-model="dataForm.dataList2[scope.$index].requ_smpl_quoter" clearable placeholder="请选择报价接收人" size="small">
                    <el-option v-for="item in userList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                  <el-tag v-if="dataForm.dataList2[scope.$index].quoter_push_state === 2" type="success">已接收</el-tag>
                  <el-tag v-if="dataForm.dataList2[scope.$index].quoter_push_state === 1" type="info">未接收</el-tag>
                </div>
              </el-form-item>
            </template>
          </CopyPasteCell>
        </template>
      </el-table-column>
      <el-table-column label="核价接收人" prop="requ_smpl_pricer" align="center" min-width="120px">
        <template v-slot="scope">
          <CopyPasteCell
            :ref="`CopyPasteCell${scope.$index}`"
            :column="'requ_smpl_pricer'"
            :copy-data="scope.row.requ_smpl_pricer"
            :index="scope.$index"
            :is-show="false"
            :type="typeof scope.row.requ_smpl_pricer"
            @changeData="data => $refs[`CopyPasteCell${scope.$index}`].changeData({ list: dataForm.dataList2, ...data })"
          >
            <template v-slot:elForm>
              <el-form-item>
                <el-select v-model="dataForm.dataList2[scope.$index].requ_smpl_pricer" clearable size="small">
                  <el-option v-for="item in userList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </CopyPasteCell>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { myMixin } from '@/assets/js/mixin/tabMixin.js';
import SearchTable from '@/components/table/SearchTable.vue';
import { dyjAPI } from '@api/modules/dyj';
import { keepNumber } from '@assets/js/regExUtil';
import CopyPasteCell from '@/components/table/CopyPasteCell.vue';
import helper from '@assets/js/helper';
import { imgeAPI } from '@api/modules/imge';

export default {
  name: 'contentTab',
  mixins: [myMixin],
  components: {
    CopyPasteCell,
    SearchTable
  },
  props: {
    dataForm: {
      type: Object,
      default: () => {}
    },
    userList: {
      type: Array,
      default: () => []
    },
    prod_unit_list: { type: Array, required: false }
  },
  computed: {
    isCopy() {
      if (this.$route.path === '/requ_add') return false;
      let { dept_name, stff_id } = this.$cookies.get('userInfo');
      return dept_name === '辅料部' || stff_id === 1;
    }
  },
  watch: {
    prod_unit_list(newValue) {
      this.unitLIst = newValue;
    }
  },
  mounted() {
    this.for5dept();
  },
  data() {
    return {
      tableProperties: [
        this.$store.state.selection,
        this.$store.state.index,
        {
          prop: 'smpl_type',
          itemType: 'select',
          label: '样品类型',
          options: [
            { value: 1, label: '新款' },
            { value: 2, label: '老款' },
            { value: 3, label: '修改款' }
          ],
          widthAuto: true,
          sortable: false,
          formatter: val => this.typeList.find(x => x.value === val).label
        },
        { prop: 'smpl_no', itemType: 'input', label: '样品编号', widthAuto: true, sortable: false },
        { prop: 'smpl_name', itemType: 'input', label: '样品名称', widthAuto: true, sortable: false },
        { prop: 'smpl_num', itemType: 'input', label: '样品数量', widthAuto: true },
        { prop: 'smpl_stff_name', itemType: 'input', label: '开版师', widthAuto: true, sortable: false }
      ],
      sampleTableData: [],
      sampleSelection: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e6;
        }
      },
      sampleDialogVisible: false,
      dialogTableVisible: false,
      unitLIst: [],
      metaList: [],
      isShowfor5dept: false,
      pedataRules: true,
      users2: [],
      selectTab: [],
      typeList: [
        { value: 1, label: '新款' },
        { value: 2, label: '老款' },
        { value: 3, label: '修改款' }
      ],
      quotList1: [
        { value: 1, label: '打样并报价' },
        { value: 3, label: '仅打样' },
        { value: 6, label: '仅报价' },
        { value: 7, label: '其他' }
      ],
      quotList: [
        { value: 1, label: '打样并报价' },
        { value: 2, label: '试样报价' },
        { value: 3, label: '仅打样' },
        { value: 4, label: '估价' },
        { value: 5, label: '返工' }
      ],
      delList: [],
      uploadUrl: helper.modePrepend(imgeAPI.uploadImage),
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 400,
        y: 400
      }
    };
  },
  methods: {
    keepNumber,
    sampleDialogShow() {
      this.sampleDialogVisible = true;
      setTimeout(() => this.getSampleData(), 500);
    },
    getSampleData() {
      dyjAPI.getDyjSmpls(this.$refs.subMultiTable.searchForm).then(({ data }) => {
        this.sampleTableData = data.list;
      });
    },
    //只有业务五部能看到
    for5dept() {
      let logUser = this.$cookies.get('userInfo');
      if (logUser.dept_id === 25) {
        this.isShowfor5dept = true;
      }
    },
    isShowRequSmplPedate(val) {
      if (this.dataList2[val].requ_smpl_quot === 1) {
        this.pedataRules.pedataRules = false;
      }
    },
    handleSelectionChange(val) {
      this.selectTab = val;
    },
    clickImport() {
      this.dialogTableVisible = true;
    },
    pldequChildChanel() {
      this.dialogTableVisible = false;
    },
    numChange() {
      let toatal = 0;
      this.dataForm.dataList2.forEach(item => {
        toatal += item.requ_smpl_num - 0;
      });
      this.dataForm.requ_num = toatal.toFixed(2);
    },
    clickDel() {
      if (this.selectTab.length === 0) {
        return this.$message.warning('至少选择一条数据');
      }
      this.$confirm('确定移除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let temp = [];
          for (let i = 0; i < this.selectTab.length; i++) {
            let ind = null;
            ind = this.dataForm.dataList2.indexOf(this.selectTab[i]);
            temp.push(ind);
          }
          temp = temp.sort((a, b) => {
            return b - a;
          });
          for (let j of temp) {
            if (this.dataForm.dataList2[j].destroy_flag !== 0) {
              let flag = this.delList.some(item => {
                return item.requ_nsmpl_id === this.dataForm.dataList2[j].requ_nsmpl_id;
              });
              if (!flag) {
                this.dataForm.dataList2[j].destroy_flag = 1;
                this.delList.push(this.dataForm.dataList2[j]);
              }
            }
            this.dataForm.dataList2.splice(j, 1);
          }
          this.numChange();
          this.$refs.contentTableArtNo.clearSelection();
        })
        .catch(() => {});
    },
    addSubTableRow() {
      this.dataForm.dataList2.push({
        prod_unit: '个',
        requ_smpl_pedate: this.dataForm.requ_pedate,
        requ_smpl_taker: this.dataForm.requ_taker, //打样接收人
        requ_smpl_quoter: this.dataForm.requ_quoter, //报价接收人
        requ_smpl_pricer: this.dataForm.requ_pricer //核价接收人
      });
    },
    pasteCell(column, row, index) {
      navigator.clipboard
        .readText()
        .then(text => {
          let textSplit = text.split('[copyDataFlag]');
          if (textSplit.length > 1 && textSplit[0] === column) {
            if (Number(textSplit[2]) === index) return;
            let rangeIndexArr;
            if (Number(textSplit[2]) > index) rangeIndexArr = [index, Number(textSplit[2])];
            else rangeIndexArr = [Number(textSplit[2]), index];
            this.dataForm.dataList2.forEach((item, tIndex) => {
              if (tIndex >= rangeIndexArr[0] && tIndex <= rangeIndexArr[1]) {
                if (textSplit[3] === 'number') {
                  this.$set(item, column, Number(textSplit[1]));
                } else {
                  this.$set(item, column, textSplit[1]);
                }
              }
            });
          }
        })
        .catch(err => {
          console.error('无法读取剪贴板: ', err);
        });
    },
    copyCell(text) {
      navigator.clipboard.writeText(text);
    },
    async copySelectedColumnsToClipboard() {
      // 构建表头
      // const headers = ['序号', '客户货号', '样品数量', '样品单位', '样品类型', '材质工艺', '委托类型', '要求交样日期'].join('\t');
      //  let dataAsText = headers + '\n'; // 首先添加表头，末尾添加换行符
      let dataAsText = ''; // 首先添加表头，末尾添加换行符
      let index = 1;
      for (const row of this.dataForm.dataList2) {
        let rowData = `${index}\t`;
        index++;
        // 按照表头顺序处理数据
        rowData += `${row.prod_cust_no || ''}\t`;
        rowData += `${row.requ_smpl_num || ''}\t`;
        rowData += `${row.prod_unit || ''}\t`;
        // 特殊处理
        const requ_smpl_type_label = this.typeList.find(x => x.value === row.requ_smpl_type)?.label || '';
        rowData += `${requ_smpl_type_label}\t`;
        rowData += `${row.sample_ements || ''}\t`;
        const quolist = this.dataForm.requ_type === 1 ? this.quotList : this.quotList1;
        const requ_smpl_quot_label = quolist.find(x => x.value === row.requ_smpl_quot)?.label || '';
        rowData += `${requ_smpl_quot_label}\t`;
        const requ_smpl_pedate_str = row.requ_smpl_pedate ? new Date(row.requ_smpl_pedate).toLocaleDateString() : '';
        rowData += `${requ_smpl_pedate_str}\t`;
        // 每行数据末尾移除最后一个多余的制表符，并添加换行符
        dataAsText += rowData.slice(0, -1) + '\n';
      }
      try {
        await navigator.clipboard.writeText(dataAsText.trim());
        this.$message.success('已复制到剪贴板');
      } catch (err) {
        this.$message.error('复制到剪贴板失败，请重试');
        console.error('复制到剪贴板失败:', err);
      }
    },
    handleAvatarSuccess(res, row) {
      if (res.code === 0) {
        this.$set(row, 'imge_id', res.data.imge_id);
        this.$set(row, 'imge_url', res.data.imge_url);
      }
    },
    beforeAvatarUpload(res) {
      const isLt5M = res.size / 1024 / 1024 < 3;
      if (!isLt5M) {
        this.$message.error('图片大小不得超过 3MB!');
        return false;
      }
    },
    handlePaste(event, index) {
      const items = (event.clipboardData || window.clipboardData).items;
      if (!items || items.length === 0) return this.$message.error('当前浏览器不支持本地');
      if (items[0].type.indexOf('image') === -1) return this.$message.error('粘贴内容非图片');
      let file = items[0].getAsFile();
      let ref = this.$refs[`uploadMultiple${index}`][0] || this.$refs[`uploadMultiple${index}`];
      ref.handleStart(file); // 将粘贴过来的图片加入预上传队列
      ref.submit(); // 提交图片上传队列
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .textarea {
  margin-bottom: 4px;
}

::v-deep .fixed-cell {
  //  color:red !important
  background-color: #ccc !important;
}

::v-deep .el-table__body-wrapper {
  z-index: 2;
}

::v-deep .el-form-item.is-required .el-form-item__label:before {
  content: '';
  display: none;
}

::v-deep .el-form-item__label {
  display: none !important;
}

.show-overflow-tooltip {
  display: block;
  overflow: hidden;
  height: 0;
}

::v-deep .el-upload {
  display: inline;
  text-align: center;
  cursor: pointer;
  outline: 0;
}

::v-deep .el-upload-dragger {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  background-color: #e6f7ff;
  border: 0;
}

::v-deep .upload-demo {
  display: inline;
}

::v-deep .el-upload--text {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f5f500;
  border: 0;
  width: 80px;
  height: 80px;
}
</style>
