<template>
  <div>
    <el-row>
      <el-col :md="8">
        <el-form-item label="样品种类:" prop="requ_type">
          <el-radio v-model="dataForm.requ_type" label="1" @change="val => updateStff(val)">一部</el-radio>
          <el-radio v-model="dataForm.requ_type" label="2" @change="val => updateStff(val)">辅料部</el-radio>
          <el-radio v-model="dataForm.requ_type" label="3" @change="val => updateStff(val)">二部</el-radio>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="款数:" prop="requ_num_mode">
          <el-input v-model="dataForm.requ_num_mode" disabled size="small"></el-input>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="公司抬头" prop="cptt_id">
          <el-select :disabled="statusType" v-model="dataForm.cptt_id" clearable filterable size="small">
            <el-option v-for="item in cpttList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mt_8">
      <el-col :md="8">
        <el-form-item label="客户简称:" prop="cust_id">
          <el-select v-model="dataForm.cust_id" clearable size="small" filterable>
            <el-option v-for="item in custs" :key="item.cust_id" :label="item.cust_abbr" :value="item.cust_id"></el-option>
          </el-select>
          <!--          <el-input v-model="dataForm.cust_abbr" filter size="small" disabled>-->
          <!--            <template slot="append">-->
          <!--              <el-link type="primary" :disabled="isShow" @click="dialogTableVisible = true" class="vg_cursor_hander"> 选择 </el-link>-->
          <!--            </template>-->
          <!--          </el-input>-->
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="样品数量:" prop="requ_num">
          <el-input v-model="requ_num" disabled size="small" style="width: 215px"></el-input>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="提交日期:" prop="sub_time">
          <el-date-picker v-model="dataForm.sub_time" clearable placeholder="选择日期" size="small" style="width: 215px" type="date" value-format="timestamp">
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mt_8">
      <el-col :md="8">
        <el-form-item label="是否提供原样:" prop="requ_sample">
          <el-radio v-model="dataForm.requ_sample" label="1">是</el-radio>
          <el-radio v-model="dataForm.requ_sample" label="0">否</el-radio>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <!-- 默认是登录的人  -->
        <el-form-item label="打样委托人:" prop="requ_stff_id">
          <el-select v-model="dataForm.requ_stff_id" clearable filterable size="small">
            <el-option v-for="item in users" :key="item.stff_id" :label="item.stff_name + '（' + item.dept_name + '）'" :value="item.stff_id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="要求交样日期:" prop="requ_pedate">
          <el-date-picker
            v-model="dataForm.requ_pedate"
            type="date"
            size="small"
            value-format="timestamp"
            @change="changeTableDate"
            clearable
            placeholder="选择日期"
            style="width: 215px"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mt_8 vg_mb_8">
      <el-col :md="16">
        <el-col :md="24" class="vg_mb_8">
          <el-form-item class="w1" label="样品要求:" prop="requ_content">
            <el-input v-model="dataForm.requ_content" :autosize="{ minRows: 4, maxRows: 100 }" class="w2" type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="24" class="vg_mb_8">
          <el-form-item class="w1" label="采购退单原因:" prop="requ_DYJreason">
            <el-input v-model="dataForm.requ_DYJreason" :autosize="{ minRows: 4, maxRows: 100 }" class="w2" disabled type="textarea"></el-input>
          </el-form-item>
        </el-col>
      </el-col>
      <el-col :md="8">
        <el-form-item label="要求核价日期:" prop="requ_smpl_qudate">
          <el-date-picker
            v-model="dataForm.requ_smpl_qudate"
            type="date"
            size="small"
            value-format="timestamp"
            @change="changeRequSmplQudate"
            clearable
            placeholder="选择日期"
            style="width: 215px"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="提交审核部门:" prop="requ_dept_id">
          <el-select v-model="dataForm.requ_dept_id" clearable filterable size="small" @change="selectChange1" disabled>
            <el-option v-for="item in group" :key="item.id" :value="item.param2" :label="item.param1"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-row>
          <el-col>
            <el-form-item label="打样接收人:" prop="requ_taker">
              <el-select v-model="dataForm.requ_taker" clearable filterable size="small" @change="changeListRequTaker">
                <el-option v-for="item in userList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="报价接收人:" prop="requ_quoter">
              <el-select v-model="dataForm.requ_quoter" clearable filterable placeholder="请选择" size="small" @change="changeListRequQuoter">
                <el-option v-for="item in userList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="核价接收人:" prop="requ_pricer">
              <el-select @change="changeListRequPricer" v-model="dataForm.requ_pricer" clearable filterable size="small">
                <el-option v-for="item in userList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-dialog title="客户信息" :visible.sync="dialogTableVisible" width="70%">
      <requImport @childDequ="childDequ"></requImport>
    </el-dialog>
  </div>
</template>

<script>
import requImport from './requImport.vue';
import { stffAPI } from '@/api/modules/staff';
import { get } from '@/api/request';
import { optnAPI } from '@/api/modules/optn';
import { getCptt, getDept } from '@api/public';
import { objectArrayReduce } from '@assets/js/arrayUtils';
import { custAPI } from '@api/modules/cust';
import { BigNumber } from 'bignumber.js';

export default {
  components: {
    requImport
  },
  props: {
    dataForm: {
      type: Object,
      default: () => {}
    },
    isShow: {
      type: Boolean,
      required: false,
      default: false
    },
    statusType: {
      type: Boolean
    },
    userList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialogTableVisible: false,
      users: [],
      group: [],
      cpttList: [],
      custs: []
    };
  },
  computed: {
    requ_num() {
      let temp = objectArrayReduce(this.dataForm.dataList, 'requ_smpl_num', 2) || 0;
      let temp2 = objectArrayReduce(this.dataForm.dataList2, 'requ_smpl_num', 2) || 0;
      let num = new BigNumber(temp).plus(temp2).toFixed(2);
      this.dataForm.requ_num = num;
      return num;
    }
  },
  watch: {
    'dataForm.requ_type'(newValue, oldValue) {
      if (newValue === '1') {
        // this.dataForm.requ_dept_id = '6';
      }
      if (newValue === '2') {
        // this.dataForm.requ_dept_id = '20';
        // this.dataForm.requ_pricer = 34; //核价接收人
        // this.dataForm.requ_taker = null; //核价接收人
      }
      if (newValue === '3') {
        // this.dataForm.requ_type = '3';
        // this.dataForm.requ_dept_id = '7';
        // this.dataForm.requ_pricer = 33; //核价接收人
        // this.dataForm.requ_taker = 33; //打样接收人
      }
    },
    formData: {
      handler(newVal, oldVal) {
        this.$emit('updateFormData', newVal);
      },
      deep: true
    },
    'dataForm.dataList'(newValue, oldValue) {
      this.dataForm.requ_num_mode = this.dataForm.dataList.length + this.dataForm.dataList2.length;
    },
    'dataForm.dataList2'(newValue, oldValue) {
      this.dataForm.requ_num_mode = this.dataForm.dataList.length + this.dataForm.dataList2.length;
    }
  },
  async mounted() {
    this.getCusts();
    this.getUsers();
    this.getGroup();
    this.cpttList = await getCptt();
    let yewu2Dept = await getDept('业务二部', 'ids');
    if (this.$route.path.indexOf('add') !== -1 && yewu2Dept.includes(this.$cookies.get('userInfo').dept_id)) {
      this.this.dataForm.cptt_id = this.cpttList?.find(({ label }) => label === '上海庆晟宠物用品有限公司')?.value;
    }
  },
  methods: {
    changeRequSmplQudate(val) {
      this.dataForm.dataList.forEach(item => (item.requ_smpl_qudate = val));
    },
    changeListRequQuoter(val) {
      //报价接收人
      this.dataForm.dataList.forEach(item => (item.requ_smpl_quoter = val));
      this.dataForm.dataList2.forEach(item => (item.requ_smpl_quoter = val));
    },
    changeListRequTaker(val) {
      //打样接收人
      this.dataForm.dataList.forEach(item => (item.requ_smpl_taker = val));
      this.dataForm.dataList2.forEach(item => (item.requ_smpl_taker = val));
    },
    changeListRequPricer(val) {
      //核价接收人
      this.dataForm.dataList.forEach(item => (item.requ_smpl_pricer = val));
      this.dataForm.dataList2.forEach(item => (item.requ_smpl_pricer = val));
    },
    getCusts() {
      get(custAPI.getAllCustsV1, { status: 2 })
        .then(res => {
          if (res.data.code === 0) {
            this.custs = res.data.data.list;
          }
        })
        .catch(err => {
          this.$message.error(err.data.msg);
        });
    },
    //要求交样日期变更
    changeTableDate(val) {
      this.$emit('changeTableDate', val);
      //类型为毛绒时 要求核价日期默认为要求交样日期可改
      if (this.dataForm.requ_type + '' === '1') {
        this.$set(this.dataForm, 'requ_smpl_qudate', val);
      }
    },
    childDequ(val) {
      this.$set(this.dataForm, 'cust_id', val.cust_id);
      this.$set(this.dataForm, 'cust_abbr', val.cust_abbr);
      this.dialogTableVisible = false;
    },
    updateStff(val) {
      if (val === '1') {
        //毛绒
        this.dataForm.requ_dept_id = '6';
        this.dataForm.requ_pricer = null; //核价接收人
        this.dataForm.requ_taker = null; //打样接收人
        //切换为毛绒时要求交样日期为当前日期加7天  要求核价日期默认为要求交样日期可改
        this.dataForm.requ_pedate = Date.now() + 604800000;
        this.dataForm.requ_smpl_qudate = this.dataForm.requ_pedate;
      }
      if (val === '2') {
        this.dataForm.requ_dept_id = '20';
        this.dataForm.requ_pricer = 34; //核价接收人
        this.dataForm.requ_taker = null; //打样接收人
      }
      if (val === '3') {
        this.dataForm.requ_type = '3';
        this.dataForm.requ_dept_id = '7';
        this.dataForm.requ_pricer = null; //核价接收人
        // this.dataForm.requ_pricer = 33; //核价接收人
        // this.dataForm.requ_taker = 33; //打样接收人
      }
      this.dataForm.requ_quoter = null; //报价接收人清空
    },
    selectChange1() {
      this.dataForm.requ_taker = null;
      this.dataForm.requ_pricer = null;
      this.dataForm.requ_quoter = null;
    },
    getUsers() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.users = res.data.data;
          }
        })
        .catch(err => {
          this.$message.error(err.data.msg);
        });
    },
    getGroup() {
      get(optnAPI.getOptnByPermId, {
        perm_id: 10007
      })
        .then(res => {
          if (res.data.code === 0) {
            this.group = res.data.data.form.optn_cntt_list;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    }
  }
};
</script>

<style scoped lang="scss">
.w1 {
  width: 100%;
}

::v-deep .w1 > .el-form-item__content {
  width: 80%;
}

::v-deep .price {
  > input {
    text-align: right;
  }
}
</style>
<style scoped>
::v-deep .el-input__inner {
  width: 215px;
}
</style>
